'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:trackPersonSelect
 * @description Select the teachers for a track.
 */
angular.module('uasApp').component('trackPersonSelect', {
  bindings: {
    track: '<',
    persons: '<',
    assignmentTypes: '<?',
    vacancies: '<',
    onChange: '&'
  },
  templateUrl: 'es6/schedule/track/track.person.select.html',
  controllerAs: 'trackPersonSelectController',
  controller: function (Assignment, Parameter, Person) {
    const trackPersonSelectController = this;

    trackPersonSelectController.$onInit = function () {
      trackPersonSelectController.personMode = true;

      Parameter.load().then(() => {
        trackPersonSelectController.showAll = Parameter.getParameterAsBoolean('schedule.teacher.show_all', false);
      });

      setSelected();
    };

    function setSelected() {
      _.forEach(trackPersonSelectController.persons, (person) => {
        person.selected = _.some(trackPersonSelectController.track.persons, { id: person.id });
      });
      _.forEach(trackPersonSelectController.vacancies, (vacancy) => {
        vacancy.selected = _.some(trackPersonSelectController.track.vacancies, { id: vacancy.id });
      });
    }

    trackPersonSelectController.onAssignmentType = function (assignmentTypeId) {
      trackPersonSelectController.track.assignmentTypeId = assignmentTypeId;

      deselect(trackPersonSelectController.persons);
      deselect(trackPersonSelectController.vacancies);

      update();
    };

    function deselect(objects) {
      _.forEach(objects, (object) => {
        object.selected = false;
      });
    }

    trackPersonSelectController.addPerson = function (personId) {
      if (angular.isUndefined(personId)) {
        return;
      }

      const found = _.find(trackPersonSelectController.persons, { id: personId });
      if (angular.isUndefined(found)) {
        Person.find(personId).$promise.then((person) => {
          trackPersonSelectController.persons.push(person);
          trackPersonSelectController.onPerson(person);
        });
      } else {
        trackPersonSelectController.onPerson(found);
      }
    };

    trackPersonSelectController.onPerson = function (person) {
      person.selected = person.selected !== true;
      if (person.selected) {
        delete trackPersonSelectController.track.assignmentTypeId;
      }

      update();
    };

    trackPersonSelectController.addVacancy = function (vacancyId) {
      if (angular.isUndefined(vacancyId)) {
        return;
      }

      const found = _.find(trackPersonSelectController.vacancies, { id: vacancyId });
      if (angular.isUndefined(found)) {
        Assignment.find(vacancyId).$promise.then((vacancy) => {
          vacancy.label = Assignment.getLabel(vacancy);
          trackPersonSelectController.vacancies.push(vacancy);
          trackPersonSelectController.onVacancy(vacancy);
        });
      } else {
        trackPersonSelectController.onVacancy(found);
      }
    };

    trackPersonSelectController.onVacancy = function (vacancy) {
      vacancy.selected = vacancy.selected !== true;
      if (vacancy.selected) {
        delete trackPersonSelectController.track.assignmentTypeId;
      }

      update();
    };

    function update() {
      const persons = _.filter(trackPersonSelectController.persons, { selected: true });
      const vacancies = _.filter(trackPersonSelectController.vacancies, { selected: true });
      const track = _.extend(trackPersonSelectController.track, { persons, vacancies });
      trackPersonSelectController.onChange({ track });
    }
  }
});