'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:PlanboardGridster
 * @description
 * # PlanboardGridster
 * Factory that adds gridster specific logic to a scope.
 */
angular.module('uasApp')
       .factory('PlanboardGridster', function (Message, entityTranslateFilter, translateFilter, $timeout, $uibModal) {

        return { init, bind };

        // Prepare the scope to work with gridster
        function init($scope, options) {

            /**
             * Defines how gridster should parse a Module instance and where to place it.
             */
            $scope.gridsterItemMap = {
                sizeY: '1',
                sizeX: 'offering.gridsterDuration',
                row: 'offering.gridsterRow',
                col: 'offering.gridsterPeriod'
            };

            function hasMultipleUsages(offering) {
                return $scope.board.getModuleGroupCount(offering.moduleId) > 1;
            }

            /**
             * Defines the gridster options
             */
            $scope.gridsterOpts = {
                mobileModeEnabled: false,
                rowHeight: '76',
                defaultSizeX: 1,
                defaultSizeY: 1,
                outerMargin: false,
                pushing: true,
                swapping: false,
                isMobile: false,
                floating: true,
                columns: options.columns,
                draggable: {
                    enabled: false,
                    start: function (e) {
                        if (!angular.element(e.target).hasClass('gridster-not-dragable')) {
                            $scope.board.dragging = true;
                            if (angular.isDefined($scope.hovered)) {
                                $scope.board.origin = $scope.hovered;
                                $scope.board.origin.clone = angular.copy($scope.board.origin);
                            }
                        } else {
                            delete $scope.board.origin;
                        }
                    },
                    stop: function () {
                        const origin = $scope.board.origin;
                        $scope.board.dragging = false;

                        if (angular.isDefined(origin)) {
                            const moved = !_.isEqual(origin.offering.period, origin.clone.offering.period);
                            const usages = hasMultipleUsages(origin.offering);
                            if ($scope.mode === 'edit' && moved && usages) {
                                $uibModal.open({
                                    controller: function ($uibModalInstance) {
                                        const modalController = this;
                                        modalController.modal = $uibModalInstance;
                                        modalController.origin = origin;
                                        modalController.groups = $scope.board.visibleGroups;
                                        modalController.moduleGroupCount = $scope.board.getModuleGroupCount(origin.offering.moduleId);
                                    },
                                    controllerAs: 'modalController',
                                    template: `
                                        <uas-planboard-move-offering
                                            modal="modalController.modal"
                                            module-group-count="modalController.moduleGroupCount"
                                            origin="modalController.origin"
                                            groups="modalController.groups"> 
                                        </uas-planboard-move-offering>
                                    `
                                });
                            }
                        }
                    }
                },
                resizable: {
                    enabled: false
                },
                isValidMove: function (event, $el, itemOptions, item) {
                    return $scope.isValidMove($el, item);
                }
            };

            $scope.setDraggable = function (group, module, offering) {
                const draggable = $scope.editable &&
                        $scope.permissions.editModuleGroupModule &&
                        group.owned === true &&
                        module.owned === true &&
                        $scope.getCombinedDisplayType(module, offering) !== 'REMOVE';

                $scope.setDraggableEnabled(draggable);

                if (draggable) {
                    $scope.hovered = { group, module, offering };
                } else {
                    delete $scope.hovered;
                }
            };

            $scope.setDraggableEnabled = function(draggable) {
                // There is a angular watch on draggable.enabled, so only change when it is really different.
                if ($scope.gridsterOpts.draggable.enabled !== draggable) {
                    $scope.gridsterOpts.draggable.enabled = draggable;
                    $scope.gridsterOpts.resizable.enabled = draggable;
                }
            };

            function isValidPeriod(item) {
                const period = {
                    start: Math.abs(item.col + 1),
                    duration: Math.abs(item.sizeX)
                };

                const valid = $scope.board.isValidPeriod(period);
                if (!valid) {
                    // Calling this async to speed up gridster a bit. Since _.template and Message.addError cost a bit of time.
                    $timeout(() => {
                        Message.addError(translateFilter('Static.Message.InvalidPeriod', {
                            start: _.result(period, 'start'),
                            duration: _.result(period, 'duration')
                        }));
                    });
                }
                return valid;
            }

            function isDuplicateOffering(offering) {
                const originalPeriod = angular.copy(_.get($scope.board, 'origin.clone.offering.period'));
                const newPeriod = angular.copy(offering.period);
                const moved = angular.isDefined(newPeriod) && !_.isEqual(newPeriod, originalPeriod);
                const duplicate = moved && $scope.board.isDuplicateOffering(offering);

                if (duplicate) {
                    $timeout(() => {
                        Message.addError(translateFilter('Static.Page.Planboard.DuplicateOffering', {
                            module: `${offering.moduleCode} ${entityTranslateFilter(offering, 'moduleLocalName', 'moduleEnglishName')}`,
                            period: entityTranslateFilter(newPeriod)
                        }));
                    });
                }
                return duplicate;
            }

            /**
             * Checks if a gridster item is doing a valid move.
             * @param {Object} item A gridster item.
             * @return {Boolean} true if period is valid and not a duplicate offering, false otherwise.
             */
            $scope.isValidMove = function ($el, item) {
                const selected = $el[0];
                if (angular.isDefined(selected) && angular.isDefined(selected.dataset)) {
                    if (selected.dataset.moveable === 'false') {
                        return false;
                    }
                }

                return isValidPeriod(item) && !isDuplicateOffering(item.model);
            };
        }

        // Binds our gridster period and duration to the board
        function bind(board, periods) {
            const periodNumbers = _.map(periods, 'period');
            const start = _.min(periodNumbers);
            const end = _.max(periodNumbers);

            board.addOnNewOfferingListener(function (offering) {
                Object.defineProperty(offering, 'gridsterPeriod', {
                    get: function () {
                        const periodStart = _.get(this.period, 'start', 1);
                        return Math.max(periodStart, start) - 1;
                    },
                    set: function (p) {
                        const periodStart = _.get(this.period, 'start');
                        if (p + 1 !== periodStart) {
                            board.moveOffering(this, p + 1, _.result(this.period, 'duration'));
                        }
                    }
                });
                Object.defineProperty(offering, 'gridsterRow', {
                    get: function () {
                        return this.row || 0;
                    },
                    set: function (r) {
                        // Only set row if not already set.
                        // This fixes a loop when gridster tries to rearrange modules that are used by different groups.
                        if (angular.isUndefined(this.row)) {
                            this.row = r;
                        }
                    }
                });
                Object.defineProperty(offering, 'gridsterDuration', {
                    get: function () {
                        const duration = _.get(this.period, 'duration');
                        const periodEnd = duration + this.period.start;
                        if (periodEnd - 1 >= end) {
                            // Period extends beyond last visible period on board
                            // Return duration up to board period
                            return end - this.period.start + 1;
                        }
                        return duration;
                    },
                    set: function (d) {
                        if (d !== _.result(this.period, 'duration')) {
                            board.moveOffering(this, _.result(this.period, 'start'), d);
                        }
                    }
                });
            });
        }
    });
