'use strict';

angular.module('uasApp').component('createForm', {
    templateUrl: 'es6/app/create/create.form.html',
    transclude: true,
    bindings: {
        rootType: '@',
        entity: '<',
        extensions: '<?',
        defaultFields: '<?',
        operations: '<',
        onCreate: '&',
        onCancel: '&'
    },
    controllerAs: 'createFormController',
    controller: function(CustomField, EntityType, Page) {
        const createFormController = this;

        const OPERATIONS_TO_EDIT = {
            'assessment': 'CREATE_ASSESSMENT',
            'module-group': 'CREATE_GROUP',
            'module': 'CREATE_MODULE',
            'organisation': 'CREATE_ORGANISATION',
            'qualification': 'CREATE_QUALIFICATION',
            'specification': 'CREATE_SPECIFICATION',
            'study': 'CREATE_STUDY'
        };

        createFormController.$onInit = function() {
            if (createFormController.rootType) {
                const operationToEdit = _.get(OPERATIONS_TO_EDIT, createFormController.rootType, 'CREATE');
                createFormController.operationsToEdit = [operationToEdit];
                loadData();
            }
        };

        function loadData() {
            createFormController.loading = true;
            EntityType.get({
                rootType: createFormController.rootType
            }).$promise.then(getItems).then((items) => {
                createFormController.items = items;
                setEvaluation();
            }).finally(() => {
                createFormController.loading = false;
            });
        }

        function getItems(type) {
            createFormController.pageId = _.get(type, 'createId');
            return Page.find(createFormController.pageId).$promise.then((page) => {
                const items = getPageItems(page);
                if (_.isEmpty(items) && !_.isEmpty(createFormController.defaultFields)) {
                    return getDefaultItems(type);
                }
                return items;
            });
        }

        function getPageItems(page) {
            const items = _.get(page, 'items', []);
            return _(items).sortBy('index')
                .map((item) => buildItem(item.field, item.index, true))
                .filter(angular.isDefined)
                .value();
        }

        function buildItem(field, index, defaultVisible) {
            const name = _.get(field, 'name');
            if (!name) {
                return;
            }

            const value = _.get(createFormController.entity, name);
            return {
                field,
                index,
                values: angular.isDefined(value) ? [value] : undefined,
                defaultVisible
            };
        }

        function getDefaultItems(type) {
            return CustomField.query({
                type: type.id
            }).$promise.then((fields) => {
                return _(fields)
                    .filter({ visible: true })
                    .sortBy(['sequence', 'name', 'id'])
                    .map((field, index) => {
                        const defaultVisible = _.includes(createFormController.defaultFields, field.name);
                        return buildItem(field, index, defaultVisible);
                    })
                    .value();
            });
        }

        createFormController.save = function(message) {
            const form = {
                entityType: createFormController.rootType,
                academicYearId: sessionStorage.academicYear,
                facultyId: _.get(createFormController.entity, 'facultyId'),
                studyId: _.get(createFormController.entity, 'studyId'),
                pageId: createFormController.pageId,
                items: Page.getItems({
                    items: createFormController.items,
                    operations: createFormController.operations,
                    operationsToEdit: createFormController.operationsToEdit
                }),
                comment: {
                    message
                }
            };

            createFormController.saving = true;
            Page.generate(form).$promise.then((entity) => {
                createFormController.onCreate({ entity });
            }).finally(() => {
                createFormController.saving = false;
            });
        };

        createFormController.cancel = function() {
            createFormController.onCancel();
        };

        function setEvaluation() {
            const entity = { type: createFormController.rootType };
            createFormController.evaluation = Page.getEvaluation(entity, createFormController.items);
        }

        createFormController.onChange = function() {
            setEvaluation();
        };
    }
});
