'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:trackAssignPage
 * @description
 * The trackAssignPage component.
 */
angular.module('uasApp').component('trackAssignPage', {
  templateUrl: 'es6/schedule/track/track.assign.page.html',
  bindings: {
    entity: '<',
    page: '<',
    process: '<?',
    workflow: '<?',
    operations: '<'
  },
  controllerAs: 'trackAssignPageController',
  controller: function (EntityService, Offering) {
    const trackAssignPageController = this;

    trackAssignPageController.$onInit = function () {
      trackAssignPageController.operationsToEdit = angular.isDefined(trackAssignPageController.workflow) ? ['EDIT_SCHEDULE_WORKFLOW'] : ['EDIT_SCHEDULE'];

      const type = EntityService.getType(trackAssignPageController.entity);

      if (type === 'module') {
        loadPeriods(trackAssignPageController.entity.id);
      }
    };

    function loadPeriods(moduleId) {
      trackAssignPageController.loading = true;
      Offering.periods({
        entityType: 'module',
        entityId: moduleId,
        periodId: _.result(trackAssignPageController.process, 'period.id')
      }).$promise.then((periods) => {
        trackAssignPageController.periods = periods;
      }).finally(() => {
        trackAssignPageController.loading = false;
      });
    }
  }
});