'use strict';

angular.module('uasApp').component('customFieldForm', {
  bindings: {
    entity: '<',
    root: '<?',
    fields: '<',
    isReadOnly: '<?',
    operations: '<',
    operationsToEdit: '<?',
    extensions: '<?',
    evaluation: '<?',
    customOnly: '@?',
    labelKey: '@?',
    labelWidth: '@?',
    viewClasses: '<?',
    onChange: '&?'
  },
  templateUrl: 'es6/fields/custom.field.form.html',
  controllerAs: 'formController',
  controller: function (Changes, CustomField) {

    const formController = this;

    formController.$onInit = function () {
      if (_.isEmpty(formController.labelWidth)) {
        formController.labelWidth = '3';
      }
    };

    formController.$onChanges = function (changes) {
      formController.values = angular.copy(formController.entity);

      if (Changes.hasChanged(changes, 'fields')) {
        setFields();
      }
    };

    function setFields() {
      let fields = _(formController.fields);
      if (formController.customOnly === 'true') {
        fields = fields.filter({ custom: true });
      }

      formController.fields_ = fields.filter({ visible: true }).map((field) => {
        field.disabled = isDisabled(field);
        return field;
      }).sortBy(['sequence', 'name']).value();
    }

    function isDisabled(field) {
      if (formController.isReadOnly) {
          return true;
      }

      return !CustomField.isEditable(field, formController.entity, formController.root);
    }

    formController.update = function() {
      if (_.isFunction(formController.onChange)) {
        formController.onChange();
      }
    };
  }
});
