'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:ActivityManager
 * @description
 * The ActivityManager service.
 */
angular.module('uasApp').factory('ActivityManager', function ($uibModal, Activity, CustomField, Offering, EntityService) {

    const IGNORED_PROPERTIES = ['id', 'externalId', 'uid', 'code', 'localName', 'englishName', 'version', 'changeType', 'created'];

    const letters = ('ABCDEFGHIJKLMNOPQRSTUVWXYZ').split('');
    const factory = {};

    factory.create = function(args) {
        $uibModal.open({
            size: 'lg',
            template: `
                <activity-create
                    activity="activityModalController.activity"
                    weeks="activityModalController.weeks"
                    period="activityModalController.period"
                    module="activityModalController.module"
                    entity="activityModalController.entity"
                    assessments="activityModalController.assessments"
                    methods="activityModalController.methods"
                    category-id="activityModalController.categoryId"
                    show-category="activityModalController.showCategory"
                    disable-type="activityModalController.disableType"
                    operations="activityModalController.operations"
                    on-select="activityModalController.onSelect(type)"
                    on-submit="activityModalController.onSave(activity)"
                    on-cancel="activityModalController.close()">
                </activity-create>
            `,
            resolve: {
                entityType: function (EntityType) {
                    return EntityType.get({
                        rootType: EntityService.getType(args.entity),
                        entityType: 'activity'
                    }).$promise;
                },
                period: function (AcademicPeriod) {
                    return AcademicPeriod.find(args.periodId).$promise;
                },
                weeks: function () {
                    return Offering.weeks({
                        entityType: 'module',
                        entityId: args.module.id,
                        periodId: args.periodId,
                        exceedPeriod: true
                    }).$promise;
                }
            },
            controllerAs: 'activityModalController',
            controller: function($uibModalInstance, entityType, period, weeks) {
                const activityModalController = this;

                activityModalController.$onInit = function() {
                    const entity = args.entity || args.module;
                    const activity = _.extend(args.activity, {
                        entity: EntityService.toReference(entity),
                        periodId: _.get(period, 'id'),
                        visible: true,
                        weeks: []
                    });

                    activityModalController.activity = _.omit(activity, IGNORED_PROPERTIES);
                    activityModalController.categoryId = args.categoryId;
                    activityModalController.period = period;
                    activityModalController.weeks = Activity.getWeeks(weeks);
                    activityModalController.module = args.module;
                    activityModalController.entity = entity;
                    activityModalController.assessments = _.filter(args.children, { type: 'assessment' });
                    activityModalController.methods = _.filter(args.children, { type: 'method' });
                    activityModalController.operations = args.operations;
                    activityModalController.disableType = args.disableType && angular.isDefined(activityModalController.activity.typeId);
                    activityModalController.showCategory = args.showCategory;

                    CustomField.query({
                        type: entityType.id
                    }).$promise.then((fields) => {
                        const field = _.find(fields, { name: 'code' });
                        const formula = _.get(field, 'formula');
                        activityModalController.generateCode = _.isEmpty(formula);
                    });
                };

                activityModalController.onSelect = function(type) {
                    if (activityModalController.generateCode === true) {
                        activityModalController.activity.code = generateCode(type);
                    }
                };

                function generateCode(type) {
                    if (type) {
                        const code = (type.code || type.externalId || '') + '-';
                        const index = _.filter(args.typeIds || [], (v) => v === type.id).length;
                        const suffix = letters[index] || '';
                        return code + suffix;
                    }
                }

                activityModalController.onSave = function(activity) {
                    activityModalController.close();
                    args.onSave(activity);
                };

                activityModalController.close = function() {
                    $uibModalInstance.dismiss();
                };
            }
        });
    };

    factory.edit = function(args) {
        const period = _.get(args, 'activity.period', {});

        $uibModal.open({
            size: 'lg',
            template: `
                <activity-edit
                    activity="activityModalController.activity"
                    category="activityModalController.category"
                    show-category="activityModalController.showCategory"
                    entity="activityModalController.entity"
                    module="activityModalController.module"
                    period="activityModalController.period"
                    weeks="activityModalController.weeks"
                    disable-type="activityModalController.disableType"
                    operations="activityModalController.operations"
                    on-submit="activityModalController.onSave(activity, all)"
                    on-cancel="activityModalController.close()">
                </activity-edit>
            `,
            resolve: {
                weeks: function ($q) {
                    if (!_.isObject(period)) {
                        return $q.resolve([]);
                    }

                    return Offering.weeks({
                        entityType: 'module',
                        entityId: args.module.id,
                        periodId: period.id,
                        exceedPeriod: true
                    }).$promise;
                }
            },
            controllerAs: 'activityModalController',
            controller: function($uibModalInstance, weeks) {
                const activityModalController = this;

                activityModalController.$onInit = function() {
                    activityModalController.activity = buildModel(args.activity);
                    activityModalController.module = args.module;
                    activityModalController.entity = args.entity || args.module;
                    activityModalController.period = angular.copy(period);
                    activityModalController.weeks = Activity.getWeeks(weeks, args.activity);
                    activityModalController.category = args.category;
                    activityModalController.operations = args.operations;
                    activityModalController.disableType = args.disableType;
                    activityModalController.showCategory = args.showCategory;
                };

                function buildModel(activity) {
                    const model = angular.copy(activity);
                    model.typeId = _.get(model, 'type.id', model.typeId);
                    return _.omitBy(model, ['type']);
                }

                activityModalController.onSave = function(activity) {
                    activityModalController.close();
                    args.onSave(activity);
                };

                activityModalController.close = function() {
                    $uibModalInstance.dismiss();
                };
            }
        });
    };

    return factory;

  });
