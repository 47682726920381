'use strict';

angular.module('uasApp').component('uasModuleGroupFields', {
  bindings: {
    moduleGroup: '<',
    fields: '<',
    form: '<',
    operations: '<',
    saveCallbacks: '<',
    workflowMode: '<?',
    extensions: '<?'
  },
  templateUrl: 'es6/group/module.group.fields.html',
  controllerAs: 'moduleGroupFieldsController',
  controller: function ($q, CustomField, GroupType) {
    const moduleGroupFieldsController = this;

    moduleGroupFieldsController.$onInit = function() {
      moduleGroupFieldsController.fields_ = _.keyBy(moduleGroupFieldsController.fields, 'name');
      moduleGroupFieldsController.selectGroupType();
    };

    moduleGroupFieldsController.selectGroupType = function () {
      delete moduleGroupFieldsController.groupType;

      const typeId = moduleGroupFieldsController.moduleGroup.typeId;
      if (typeId) {
        return GroupType.get({
          id: typeId
        }).$promise.then((groupType) => {
          moduleGroupFieldsController.groupType = groupType;
          moduleGroupFieldsController.onEvaluate();
        });
      }
      moduleGroupFieldsController.onEvaluate();
      return $q.resolve();
    };

    moduleGroupFieldsController.onEvaluate = function () {
      const entity = {
        type: 'module-group',
        id: _.result(moduleGroupFieldsController.moduleGroup, 'id')
      };

      moduleGroupFieldsController.evaluation = CustomField.getEvaluation(
        entity,
        moduleGroupFieldsController.moduleGroup,
        moduleGroupFieldsController.fields);
    };
  }
});
