'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:notOwnedIcon
 * @description
 * notOwnedIcon Shows the not owned icon
 */
angular.module('uasApp')
  .component('notOwnedIcon', {
    bindings: {
        owner: '<'
    },
    templateUrl: 'es6/planboard/icon/not.owned.icon.html',
    controllerAs: 'notOwnedIconController'
});
