'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Planboard
 * @description
 * The Planboard service.
 */
angular.module('uasApp')
       .factory('Planboard', function($log, $q, Tree, Planning, Offering) {
         const OPENED_GROUPS_KEY = 'openedGroups';

        /**
         * Constructor.
         */
        let Planboard = function() {
            this.listeners = [];
            this.counter = 0;
            this.visible = {};
            this.groups = new Tree([]);
            this.visibleGroups = [];
            this.dragging = false;
            this.expanded = false;
        };

        /**
         * Instantiates group tree and caches.
         */
        Planboard.prototype.init = function(study, groups, periods, columns, callback) {
            const instance = this;
            instance.study = study;

            _.each(groups, (group) => {
                group.boardId = instance.counter++;
                group.origin = {
                    required: group.required,
                    changeType: group.changeType
                };

                instance.updateOwned(group, group.moduleGroupFacultyId, group.moduleGroupStudyId);
            });

            instance.groups = new Tree(groups, 'moduleGroupId', 'parentId');
            instance.periods = periods;
            instance.callback = callback;
            instance.columns = columns;

            updateVisibleGroups(instance);

            // Cache to keep all changed in sync
            instance.subGroupsCache = {};
            instance.modulesCache = {};
            instance.offeringsCache = {};
            instance.groupCountCache = {};

            return updateOpenedGroups(instance).finally(() => {
                updateVisibleGroups(instance);
            });
        };

        Planboard.prototype.expandAll = function() {
            const instance = this;

            const open = instance.expanded !== true;
            instance.expanded = open;

            return this.groups.traverse((group) => {
                return loadGroup(group, instance).then(() => {
                    const expandable = group.expandable === true;
                    if (expandable || open === false) {
                        setOpen(group, open, instance);
                    }
                    return expandable;
                });
            }).finally(() => {
                updateVisibleGroups(instance);
            });
        };

        Planboard.prototype.getClosedGroups = function() {
            let closedGroups = [];

            return this.groups.traverse((group) => {
                if (group.isOpen !== true) {
                    closedGroups = closedGroups.concat(group.moduleGroupId);
                }
            }).then(() => closedGroups);
        };

        Planboard.prototype.getOpenedGroups = function() {
            return getOpenedGroups();
        };

        Planboard.prototype.updateOwned = function(container, facultyId, studyId) {
            const faculty = angular.isDefined(facultyId) && facultyId === this.study.facultyId;
            const study = angular.isUndefined(studyId) || studyId === this.study.id;
            const owned = faculty && study;

            container.owned = owned;
            container.expandable = owned || angular.isUndefined(studyId);
        };

        function isExclude(item) {
            return item.created && item.changeType === 'REMOVE' && item.dirty === false;
        }

        /*
         * Sums all the ects of the given modules for the given period.
         * @param {Array} modules The array of modules.
         * @param {Number} periode The period.
         */
        Planboard.sumEcts = function(group, periodNumber) {
            if (!group.modules) {
                return 0;
            }

            var roundFactor = Math.pow(10, 1);
            var sum = 0;

            _.each(group.modules, function(module) {
              if (isActive(module)) {
                _.each(module.offerings, function(offering) {
                  if (isActive(offering)) {
                    var credits = module.moduleOptimumCredits;
                    var start = _.result(offering.period, 'start') || 1;
                    var duration = _.result(offering.period, 'duration') || 1;
                    if (credits && start <= periodNumber && start + duration > periodNumber) {
                      sum += (credits / duration);
                    }
                  }
                });
              }
            });

            return Math.round((sum * roundFactor)) / roundFactor;
        };

        function isActive(object) {
          return object.changeType !== 'REMOVE' && object.moduleTerminated !== true;
        }

      /*
       * Sums all the ects of the given modules for the given group.
       * @param {Array} modules The array of modules.
       * @param {Boolean} if module is required or not.
       */
        Planboard.totalEcts = function(group, type) {
            if (!group.modules) {
                if (type === 'total') {
                    return group.totalEcs;
                } else {
                    return group.requiredEcs;
                }
            }

            var sum = 0;
            var modules = type === 'total' ? group.modules : _.filter(group.modules, 'required');
            _.each(modules, function(module) {
                if (isActive(module)) {
                    _.each(module.offerings, function(offering) {
                        if (isActive(offering)) {
                            var credits = module.moduleOptimumCredits;
                            if (credits) {
                                sum += credits;
                            }
                        }
                    });
                }
            });

            return Math.round(sum * 100) / 100;
        };

        /**
         * Register a listener that is notified on new block.
         */
        Planboard.prototype.addOnNewOfferingListener = function(listener) {
            this.listeners.push(listener);
        };

        Planboard.prototype.onNewOffering = function(offering) {
            if (angular.isDefined(offering)) {
                offering.boardId = this.counter++;
                _.each(this.listeners, function(listener) {
                    listener(offering);
                });
            }
        };

        Planboard.prototype.isEmpty = function() {
            return this.groups.getRoots().length === 0;
        };

        // Refresh

        Planboard.prototype.refreshBoard = function () {
            return Planning.get({
                studyId: this.study.id
            }).$promise.then((planning) => {
                this.visible = {};
                return this.init(this.study, planning.moduleGroups, this.periods, this.columns);
            });
        };

        Planboard.prototype.refreshGroup = function(group) {
            const instance = this;

            // Set loaded to false, so it will be fetched again.
            group.loaded = false;

            // Clear the modules cache for this group.
            instance.modulesCache[group.moduleGroupId] = null;

            return loadGroup(group, instance).then((loadedGroup) => {
              setOpen(loadedGroup, true, instance);
              group = loadedGroup;
              return loadedGroup;
            }).finally(() => {
              updateVisibleGroups(instance);
            });
        };

        Planboard.prototype.refreshGroupByModuleGroupId = function(moduleGroupId) {
            const instance = this;
            let groupToRefresh = null;
            return this.groups.traverse((group) => {
                if (group.target.type === 'module-group' && group.target.id === moduleGroupId) {
                   groupToRefresh = group;
                }
            }).then(() => {
              if (groupToRefresh === null) {
                throw 'Invalid group to refresh';
              }

              return instance.refreshGroup(groupToRefresh);
            });
        };

        //
        // Load operations
        //

        /**
         * Toggles the visibility of a module group.
         */
        Planboard.prototype.toggleGroup = function(group) {
            const instance = this;

            return loadGroup(group, instance).then(() => {
                return toggleGroupVisibility(group, instance);
            });
        };

        function loadGroup(group, instance) {
            if (group.loaded !== true) {
                group.loading = true;
                return Planning.children({
                    studyId: instance.study.id,
                    moduleGroupId: group.moduleGroupId,
                    attribute: instance.attribute || '',
                    subjectTypeId: instance.subjectTypeId,
                    subjectId: instance.subjectId
                }).$promise.then((data) => {
                    _.forEach(data.moduleGroups, (mg) => {
                        mg.path = group.path + '-' + mg.moduleGroupCode;
                    });
                    loadSubGroupLinks(instance, group, data);
                    loadModuleLinksInGroup(instance, group, data);
                    setRows(group);
                    return onLoaded(group, instance);
                }).finally(() => {
                    group.loaded = true;
                    group.loading = false;
                });
            } else {
                return $q.resolve(group);
            }
        }

        function onLoaded(group, instance) {
            if (_.isFunction(instance.callback)) {
                return instance.callback(group);
            } else {
                return group;
            }
        }

        function toggleGroupVisibility(group, instance) {
            const open = instance.visible[group.path] !== true;
            setOpen(group, open, instance);
            updateVisibleGroups(instance);
            return group;
        }

        function setOpen(group, open, instance) {
            instance.visible[group.path] = open;
            group.isOpen = open;
            manageOpenGroups(group);
            return open;
        }

        function manageOpenGroups(group) {
            const openedGroups = getOpenedGroups();

            if (group.isOpen) {
              const found = _.find(openedGroups, { type: group.self.type, id: group.self.id });
              if (_.isEmpty(found)) {
                openedGroups.push(group.self);
              }
            } else {
              _.remove(openedGroups, { type: group.self.type, id: group.self.id });
            }

            setOpenedGroups(openedGroups);
        }

        function getOpenedGroups() {
            const openedGroups = sessionStorage.getItem(OPENED_GROUPS_KEY);

            if (_.isEmpty(openedGroups)) {
                return [];
            }

            return angular.fromJson(openedGroups);
        }

        function setOpenedGroups(openedGroups) {
            const groups = _.defaultTo(openedGroups, []);

            sessionStorage.setItem(OPENED_GROUPS_KEY, angular.toJson(groups));
        }

        function updateOpenedGroups(instance) {
            const openedGroups = getOpenedGroups();

            return instance.groups.traverse((group) => {
                const found = _.find(openedGroups, { type: group.self.type, id: group.self.id });

                if (!_.isEmpty(found)) {
                    return loadGroup(group, instance).then(() => {
                        return setOpen(group, true, instance);
                    });
                }
            });
        }

        /**
         * Sets the rows of each offering grouped by the offering.period.start
         * @param {Object} group
         */
        function setRows(group) {
            _(group.modules)
                .map('offerings')
                .filter(Boolean)
                .flatten()
                .sortBy(function(offering) {
                    if (offering.period) {
                        return offering.period.duration || 0;
                    } else {
                        return 0;
                    }
                })
                .each(function(offering, index) {
                    if (offering.row === null || angular.isUndefined(offering.row) || offering.row < index) {
                        offering.row = index;
                    }
                });
        }

        function loadSubGroupLinks(instance, group, data) {
            function addSubGroupsToTree(mgmgs) {
                _.each(mgmgs, function(mgmg) {
                    var subgroup = _.extend(mgmg, {
                        origin: {
                            required: mgmg.required,
                            changeType: mgmg.changeType
                        }
                    });

                    instance.updateOwned(subgroup, subgroup.moduleGroupFacultyId, subgroup.moduleGroupStudyId);

                    if (instance.groups.find(subgroup, group) === null) {
                        instance.groups.add(subgroup, group);
                    }
                });
            }

            let cachedSubGroups = instance.subGroupsCache[group.path];
            if (cachedSubGroups) {
                addSubGroupsToTree(cachedSubGroups);
            } else {
                let children = instance.subGroupsCache[group.path] = data.moduleGroups;
                addSubGroupsToTree(children);
            }
        }

        function loadModuleLinksInGroup(instance, group, data) {
            var cachedModules = instance.modulesCache[group.moduleGroupId];
            if (cachedModules) {
                group.modules = cachedModules;
            } else if (angular.isDefined(group.moduleGroupId)) {
                var moduleGroupModules = buildAllModuleLinksInGroup(instance, data);
                group.modules = instance.modulesCache[group.moduleGroupId] = moduleGroupModules;
                _.each(data.offerings, function(module) {
                    if (!instance.groupCountCache[module.moduleId]) {
                        instance.groupCountCache[module.moduleId] = module.moduleGroupCount;
                    }
                });
            } else {
                group.modules = [];
                group.offerings = [];
            }
        }

        function buildAllModuleLinksInGroup(instance, data) {
            var modules = [];

            // Store the offerings
            var perModule = _.groupBy(data.offerings, 'moduleId');
            _.each(perModule, function(ofModule, moduleId) {
                var offerings = instance.offeringsCache[moduleId];
                if (!offerings) {
                    offerings = [];
                    _.each(ofModule, (moduleGroupModuleOffering) => {
                        const offering = mapToOffering(moduleGroupModuleOffering, instance);

                        if (angular.isDefined(offering.period) && !isExclude(offering) && angular.isDefined(offering.id) && isValidPeriod(offering.period, instance)) {
                            offerings.push(offering);
                            instance.onNewOffering(offering);
                        }
                    });
                    instance.offeringsCache[moduleId] = offerings;
                }
            });

            // Store the modules
            _.each(data.modules, function(module) {
                module = _.extend(module, {
                    origin: {
                        required: module.required,
                        changeType: module.changeType
                    }
                });

                instance.updateOwned(module, module.moduleFacultyId, module.moduleStudyId);
                if (!isExclude(module)) {
                    module.offerings = instance.offeringsCache[module.moduleId];
                    modules.push(module);
                }
            });

            return modules;
        }

        function mapToOffering(input, instance) {
            const offering = {
                id: input.id,
                moduleGroupCount: input.moduleGroupCount,
                moduleId: input.moduleId,
                moduleCode: input.moduleCode,
                moduleLocalName: input.moduleLocalName,
                moduleEnglishName: input.moduleEnglishName,
                moduleFacultyId: input.moduleFacultyId,
                moduleStudyId: input.moduleStudyId,
                moduleOfferingId: input.moduleOfferingId,
                period: fetchPeriodById(input.periodId, instance),
                origin: {
                    period: fetchPeriodById(input.originalPeriodId, instance)
                }
            };

            if (!(input.changeType === 'MODIFY' && input.periodId === input.originalPeriodId)) {
                offering.changeType = input.changeType;
            }

            return offering;
        }

        function fetchPeriodById(periodId, instance) {
            return _.find(instance.periods, { id: periodId });
        }

        function isValidPeriod(period, instance) {
            return period && period.start <= instance.columns && period.end >= 1;
        }

        //
        // Change operations
        //

        /**
         * Determines if a period is usable.
         */
        Planboard.prototype.isValidPeriod = function(period) {
            return angular.isDefined(this.findPeriod(period));
        };

        Planboard.prototype.findPeriod = function(input) {
            const args = {
                start: input.start,
                duration: input.duration,
                selectable: true
            };

            return _.find(this.periods, args);
        };

        Planboard.prototype.isDuplicateOffering = function(offering) {
            return _(this.offeringsCache[offering.moduleId])
                .filter((o) => o.boardId !== offering.boardId)
                .filter((o) => o.period.id === offering.period.id)
                .value()
                .length > 0;
        };

        // Module group

        /**
         * Add a new module group to the board.
         */
        Planboard.prototype.addModuleGroup = function(group, parent, study) {
            var children = this.getModuleGroupChildren(parent);
            var existing = _.find(children, {
                moduleGroupId: group.id
            });

            if (angular.isDefined(existing)) {
                // Restore the previously removed module group
                if (existing.changeType === 'REMOVE') {
                    existing.dirty = true;
                    existing.required = group.required === true;
                    if (angular.isUndefined(existing.id) || existing.created === true) {
                        existing.changeType = 'CREATE';
                    }
                }
            } else {
                // Create a new module group and add to structure
                var newModuleGroup = {
                    moduleGroupId: group.id,
                    moduleGroupCode: group.code,
                    moduleGroupAbbreviation: group.abbreviation,
                    moduleGroupLocalName: group.localName,
                    moduleGroupEnglishName: group.englishName,
                    moduleGroupFacultyId: group.facultyId,
                    required: group.required === true,
                    description: group.description,
                    values: group.values,
                    changeType: 'CREATE',
                    boardId: this.counter++,
                    dirty: true,
                    loaded: angular.isUndefined(group.id),
                    isOpen: false,
                    origin: {
                        required: false,
                        changeType: 'CREATE'
                    },
                    modules: []
                };

                this.updateOwned(newModuleGroup, group.facultyId, group.studyId);

                if (parent) {
                    newModuleGroup.parentId = parent.moduleGroupId;
                    this.groups.add(newModuleGroup, parent);
                } else {
                    newModuleGroup.studyId = study.id;
                    this.groups.add(newModuleGroup);
                }
            }

            updateVisibleGroups(this);
        };

        Planboard.prototype.getModuleGroupChildren = function(parent) {
            var children = [];
            if (parent) {
                var foundParent = this.groups.find(parent);
                if (foundParent) {
                    children = this.groups.findChildren(foundParent);
                }
            } else {
                children = this.groups.findChildren();
            }
            return children;
        };

        /**
         * Removes a module group from the board.
         */
        Planboard.prototype.removeModuleGroup = function(group) {
            if (angular.isUndefined(group.id)) {
                this.groups.remove(group);
            } else if (group.changeType === 'CREATE') {
                group.exclude = true;
                var instance = this;
                _.each(this.groups.children, function(child) {
                    instance.hidden[child.boardId] = true;
                });
            }
            group.dirty = true;
            group.changeType = 'REMOVE';
            updateVisibleGroups(this);
        };

        Planboard.prototype.resetModuleGroup = function(group) {
            if (group.dirty) {
                group.changeType = undefined;
                group.dirty = false;
            } else {
                group.dirty = true;
                group.changeType = 'CREATE';
            }
            updateVisibleGroups(this);
        };

        // Module

        Planboard.prototype.getModuleGroupCount = function(moduleId) {
            return this.groupCountCache[moduleId] ? this.groupCountCache[moduleId] : 1;
        };

        var decreaseModuleGroupCount = function(instance, moduleId) {
            if (instance.groupCountCache[moduleId]) {
                instance.groupCountCache[moduleId]--;
            }
        };

        var increaseModuleGroupCount = function(instance, moduleId, initial) {
            if (moduleId) {
                if (!instance.groupCountCache[moduleId]) {
                    instance.groupCountCache[moduleId] = initial;
                }
                instance.groupCountCache[moduleId]++;
            }
        };

        /**
         * Add a module to the board.
         * Returns a promise that resolves to the module with offerings
         * or rejects if duplicate module was added to module group.
         */
        Planboard.prototype.addModule = function(module, group) {
            const instance = this;
            const existing = _.find(group.modules, {
                moduleId: module.id
            });
            if (!existing) {
                return loadGroup(group, instance).then(() =>
                    addModuleToGroup(module, group, instance)
                );
            } else {
                $log.error('[Planboard] 1/4 Blocked attempt to add duplicate module to module group');
                $log.error('[Planboard] 2/4 Module:');
                $log.error(module);
                $log.error('[Planboard] 3/4 Module group:');
                $log.error(group);
                $log.error('[Planboard] 4/4 Planboard contents:');
                $log.error(instance);
                return $q.reject('Duplicate module was added to module group');
            }
        };

        function addModuleToGroup(module, group, instance) {
            const newModule = createModule(module, group);
            instance.updateOwned(newModule, module.facultyId, module.studyId);
            increaseModuleGroupCount(instance, module.id, module.moduleGroupCount);

            // Assign offering to the module added to the group. Note that offerings are stored per module, not per module group!
            const offerings = instance.offeringsCache[module.id];
            if (offerings) {
                // Add module to group with cached offering data
                newModule.offerings = offerings;
                group.modules.push(newModule);
                return $q.resolve(newModule);
            } else {
                return refreshModuleOfferings(newModule, instance).then(() => {
                    if (group.modules) {
                        group.modules.push(newModule);
                    } else {
                        group.modules = [newModule];
                    }
                    return newModule;
                });
            }
        }

        function createModule(module, group) {
            // Create relation between module and module group (ModuleGroupModule)
            return {
                moduleId: module.id,
                moduleCode: module.code,
                moduleLocalName: module.localName,
                moduleEnglishName: module.englishName,
                moduleGroupId: group.moduleGroupId,
                moduleOptimumCredits: module.optimumCredits || 0,
                moduleFacultyId: module.facultyId,
                moduleStudyId: module.studyId,
                required: module.required === true,
                values: module.values,
                offerings: [],
                changeType: 'CREATE',
                dirty: true,
                origin: {
                    required: false,
                    changeType: 'CREATE'
                }
            };
        }

        /**
         * Obtain actual offerings for the module from the server.
         * @param {*} module the module to refresh offerings
         * @param {*} instance the planboard instance
         */
        function refreshModuleOfferings(module, instance) {
            return Offering.query({
                entityId: module.moduleId,
                entityType: 'module'
            }).$promise.then((offerings) => {
                _.each(offerings, (offering) => {
                    if (!isExclude(offering)) {
                        const newOffering = {
                            id: offering.id,
                            moduleId: module.moduleId,
                            moduleLocalName: module.moduleLocalName,
                            moduleEnglishName: module.moduleEnglishName,
                            moduleCode: module.moduleCode,
                            moduleOfferingId: offering.id,
                            period: _.find(instance.periods, { id: offering.periodId })
                        };

                        // Add offering from the server to the module
                        instance.onNewOffering(newOffering);
                        module.offerings.push(newOffering);
                    }
                });
                instance.offeringsCache[module.moduleId] = module.offerings;
                return module;
            });
        }

        /**
         * Removes a module from a group on the board.
         */
        Planboard.prototype.removeModuleFromGroup = function(module, group) {
            // If the module was added by the user, dont soft remove it, but hard remove it.
            if (angular.isUndefined(module.id)) {
                _.remove(group.modules, module);
            } else if (module.changeType === 'CREATE') {
                module.exclude = true;
            }
            decreaseModuleGroupCount(this, module.moduleId);
            module.changeType = 'REMOVE';
            module.dirty = true;
        };

        /**
         * Removes a module from the board.
         */
        Planboard.prototype.removeModule = function (module) {
            _.each(getModuleInAllGroups(module, this), (moduleInGroup) => {
                moduleInGroup.moduleTerminated = true;
                moduleInGroup.terminatePerformed = true;
                moduleInGroup.dirty = true;
                moduleInGroup.changeType = 'REMOVE';
            });
        };

        function getModuleInAllGroups(module, instance) {
            const groups = getChildren(instance);
            return _(groups)
                .map((group) => _.find(group.modules, { moduleId: module.moduleId }))
                .omitBy(_.isUndefined)
                .value();
        }

        /**
         * Changes the required status of a module.
         */
        Planboard.prototype.toggleRequired = function(entity) {
            entity.origin = entity.origin || {};
            if (angular.isUndefined(entity.origin.required)) {
                entity.origin.required = entity.required;
            }
            entity.required = !entity.required;
            if (entity.required !== entity.origin.required) {
                entity.changeType = 'MODIFY';
                entity.dirty = true;
            } else {
                entity.changeType = entity.origin.changeType;
            }
        };

        // Offering

        Planboard.prototype.moveOfferingToGroup = function(offering, group, mgm) {
            const module = buildModuleFromMgm(mgm);
            this.addModule(module, group);
            this.removeModuleFromGroup(this.origin.module, this.origin.group);
        };

        /**
         * Makes a clone of the given offering and adds it the correct module in the given group.
         * @param {Object} offering An offering to be added.
         * @param {Object} group A module group.
         * @param {Object} mgm Existing relation between a module and a module group.
         */
        Planboard.prototype.copyOfferingToGroup = function(offering, group, mgm) {
            const module = buildModuleFromMgm(mgm);
            this.addModule(module, group);
        };

        function buildModuleFromMgm(mgm) {
            return {
                id: mgm.moduleId,
                code: mgm.moduleCode,
                localName: mgm.moduleLocalName,
                englishName: mgm.moduleEnglishName,
                optimumCredits: mgm.moduleOptimumCredits,
                facultyId: mgm.moduleFacultyId,
                studyId: mgm.moduleStudyId,
                required: mgm.required
            };
        }

        /**
         * Move (drag) an offering. Changing a module offering will
         * have effect on all usages of the modules.
         */
        Planboard.prototype.moveOffering = function(offering, start, duration) {
            var dirty = true;

            var args = { start, duration };
            var period = this.findPeriod(args);
            if (angular.isDefined(period)) {
                if (!offering.changeType || offering.changeType !== 'REMOVE') {
                    if (!offering.hasOwnProperty('origin')) {
                        offering.origin = {};
                    }
                    offering.origin.session = offering.origin.session || {};

                    if (isEmpty(offering.origin.session)) {
                        offering.origin.session = offering.period;
                    } else if (isSamePeriod(offering.origin.session, period)) {
                        offering.origin.session = {};
                        dirty = angular.isUndefined(offering.id);
                    }

                    if (isEmpty(offering.origin.period)) {
                        offering.origin.period = offering.origin.session;
                    }

                    offering.dirty = dirty;

                    if (isSamePeriod(offering.origin.period, period)) {
                        var isNew = offering.created || angular.isUndefined(offering.id);
                        offering.changeType = isNew ? 'CREATE' : '';
                    } else if (angular.isDefined(offering.id)) {
                        offering.changeType = 'MODIFY';
                    }
                }

                offering.period = period;
            }
        };

        var isEmpty = function(period) {
            if (!period) {
                return true;
            }
            return !period.start || !period.duration;
        };


        Planboard.prototype.hasChangedPosition = function(offering) {
            return !isSamePeriod(offering.period, offering.previousPeriod);
        };

        var isSamePeriod = function(left, right) {
            return left.start === right.start &&
                left.duration === right.duration &&
                left.partOfDayId === right.partOfDayId;
        };

        /**
         * Removes an offering from a module.
         */
        Planboard.prototype.removeOffering = function (offering) {
            if (angular.isUndefined(offering.id)) {
                var params = { boardId: offering.boardId };

                // Not yet saved, remove from board
                _.remove(this.offeringsCache[offering.moduleId], params);

                var groups = getChildren(this);
                _.each(groups, function (group) {
                    _.each(group.modules, function (module) {
                        _.remove(module.offerings, params);
                    });
                });
            } else {
                // Already saved, update
                if (offering.changeType === 'CREATE') {
                    offering.exclude = true;
                }
                offering.changeType = 'REMOVE';
                offering.dirty = true;
            }
        };

        function resetRemove(object) {
            object.exclude = false;
            object.changeType = (object.dirty) ? undefined : 'RESET';
            object.dirty = !object.dirty;
        }

        /**
         * Restore the offering.
         */
        Planboard.prototype.resetOffering = function (offering, module) {
            if (module.owned === true && canUndoOffering(offering, module)) {
                resetRemove(offering);
                resetRemove(module);
            } else if (canUndoModule(module)) {
                if (module.moduleTerminated) {
                    _.each(getModuleInAllGroups(module, this), (moduleInGroup) => {
                        moduleInGroup.moduleTerminated = false;
                        moduleInGroup.terminatePerformed = false;
                        resetRemove(moduleInGroup);
                    });
                } else {
                    resetRemove(module);
                }
            }
        };

        function canUndoOffering(offering, module) {
            return offering.changeType === 'REMOVE' && module.moduleTerminated !== true;
        }

        function canUndoModule(module) {
            return module.moduleTerminated || module.changeType === 'REMOVE';
        }

        /**
         * Determines if an undo is possible.
         */
        Planboard.prototype.canUndo = function (offering, module) {
            return module.owned === true && (canUndoOffering(offering, module) || canUndoModule(module));
        };

        //
        // Retrieve operations
        //

        /*
         * Returns all visible groups for the given root and rootPath
         * @param  {Object} root     The root of the tree
         * @param  {String} rootPath The path of root to a leave
         * @return {Array}          All visible groups.
         */
        function getAllVisibleGroups (instance, root, rootPath) {
            if (rootPath && !instance.visible[rootPath]) {
                return [];
            }

            const children = sortModuleGroups(instance.groups.findChildren(root));
            if (children.length > 0) {
                _.last(children).last = true;
            }

            return _(children)
                .filter((child) => !isExclude(child))
                .map((child) => {
                    var path = (rootPath ? rootPath + '-' : '') + child.moduleGroupCode;
                    var subResults = getAllVisibleGroups(instance, child, path);
                    subResults.unshift(_.extend(child, {
                        path: path
                    }));
                    return subResults;
                })
                .flatten()
                .filter(Boolean)
                .value();
        }

        function sortModuleGroups(groups) {
            return _.sortBy(groups, ['sequence', 'moduleGroupSequence', 'moduleGroupCode', 'moduleGroupId']);
        }

        function updateVisibleGroups(instance) {
            instance.visibleGroups = getAllVisibleGroups(instance);
        }

        function getChildren(instance, root, rootPath) {
            return _(instance.groups.findChildren(root))
                .filter(function(child) {
                    return !isExclude(child);
                })
                .map(function(child) {
                    var path = (rootPath ? rootPath + '-' : '') + child.moduleGroupCode;
                    var result = getChildren(instance, child, path);
                    result.unshift(_.extend(child, {
                        path: path
                    }));
                    return result;
                })
                .flatten()
                .value();
        }

        /**
         * Retrieve all changes made in the board, so we can send
         * the delta's to the backend.
         */
        Planboard.prototype.getAllDirty = function() {
            const instance = this;

            var groups = [];
            var modules = [];
            var offerings = {};

            var children = getChildren(instance);
            _.each(children, function(group) {
                if (group.dirty === true) {
                    groups.push(group);
                }
                if (group.modules) {
                    _.each(group.modules, function(module) {
                        if (module.dirty === true) {
                            modules.push(module);
                        }
                        _.each(module.offerings, function(offering) {
                            if (offering.dirty === true) {
                                offerings[module.moduleId + '-' + offering.boardId] = offering;
                            }
                        });
                    });
                }
            });

            return {
                groups: groups,
                modules: modules,
                offerings: _.values(offerings),
                type: 'planboard'
            };
        };

        Planboard.prototype.hasUnsavedChanges = function () {
            const dirty = this.getAllDirty();
            return (dirty.groups.length > 0 || dirty.modules.length > 0 || dirty.offerings.length > 0);
        };

        // Details

        Planboard.prototype.refreshModuleGroupModules = function(moduleId, moduleGroupModules) {
            return moduleGroupModules; // TODO: Implement this
        };

        // History

        /**
         * Toggles between showing history and removing the selected offering.
         * @param {Object} module The module containing the offerings.
         * @param {Object} offering A offering for which the history must be shown.
         */
        Planboard.prototype.toggleHistory = function(module, offering) {
            var removed = _.remove(module.offerings, {
                temp: true
            });
            if (removed.length === 0 || offering.id !== removed[0].id) {
                this.showHistory(module, offering);
            }
        };

        /**
         * Called when switching from MODE in the planboard view.
         * Removes the previous history modules.
         */
        Planboard.prototype.removeHistory = function() {
            if (this.previousHistory) {
                _.remove(this.previousHistory.offerings, {
                    temp: true
                });
            }
        };

        /**
         * Creates a new object that shows the old state of the @code{offering}.
         * @param {Object} module The module containing the offerings.
         * @param {Object} offering The offering object to show the origin of it.
         */
        Planboard.prototype.showHistory = function(module, offering) {
            if (offering.changeType === 'MODIFY') {
                this.removeHistory();
                var tmpOffering = {
                    temp: true,
                    movable: false,
                    period: offering.origin.period,
                    id: offering.id
                };
                this.onNewOffering(tmpOffering);

                // In temp mode there is more space, so we can show more of the text.
                tmpOffering.period.truncation += 50;
                module.offerings.push(tmpOffering);

                // Save the parent of the offering so we can remove it easily.
                this.previousHistory = module;
            }
        };

        Planboard.prototype.getStyleClasses = function (prefix, changeType) {
            const style = getStyle(changeType);
            return [prefix, `${prefix}-${style}`];
        };

        function getStyle(changeType) {
            switch (changeType) {
                case 'REMOVE':
                    return 'danger';
                case 'MODIFY':
                    return 'warning';
                case 'CREATE':
                    return 'success';
                case 'RESET':
                    return 'info';
                default:
                    return 'primary';
            }
        }

        Planboard.prototype.getCombinedDisplayType = function (module, offering) {
            const moduleType = getDisplayType(module);
            const offeringType = getDisplayType(offering);
            if (moduleType === 'REMOVE' || offeringType === 'REMOVE' || module.moduleTerminated) {
                return 'REMOVE';
            } else if (moduleType === 'CREATE') {
                return 'CREATE';
            }
            return offeringType || moduleType;
        };

        function getDisplayType(object) {
            object = object || {};
            if (object.temp) {
                return '';
            }
            return object.changeType;
        }

        return Planboard;
    });
