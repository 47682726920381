'use strict';

angular.module('uasApp').directive('pageRender', function ($log, $compile, EntityService, PAGES) {
    return {
        restrict: 'A',
        scope: {
            page: '<',
            entity: '<',
            operations: '<',
            extensions: '<?',
            period: '<',
            process: '<',
            workflow: '<',
            flow: '<',
            isReadOnly: '<?',
            onEvaluate: '&?'
        },
        link: function link(scope, element) {

            scope.$watch('page', () => {
                update();
            });

            function update() {
                if (angular.isDefined(scope.page)) {
                    let name = scope.page.route;
                    if (_.startsWith(name, 'workflow.')) {
                        name = name.substring('workflow.'.length);
                    }

                    render(name);
                }
            }

            function render(name) {
                element.empty();

                const template = _.find(PAGES, { 
                    name: name || 'form'
                });

                if (angular.isDefined(template)) {
                    const entityType = EntityService.getType(scope.entity);
                    const operationsToView = scope.page.operation || _.get(template, 'operation', '');
                    const html = `
                        <authorized requested="${operationsToView}" operations="operations" show-error="true">
                            <${template.component} 
                                page="page" 
                                entity="entity" 
                                ${entityType}="entity" 
                                extensions="extensions"
                                operations="operations"
                                period="period || process.period"
                                process="process"
                                workflow="workflow"
                                flow="flow"
                                is-read-only="isReadOnly === true"
                                on-evaluate="onEvaluate({ evaluation })">
                            </${template.component}>
                        </authorized>
                    `;

                    const component = angular.element(html);
                    $compile(component)(createScope());

                    element.append(component);
                } else {
                    $log.error(`Could not find component ${name}`);
                }
            }

            function createScope() {
                if (scope.elementScope) {
                    scope.elementScope.$destroy();
                }
                scope.elementScope = scope.$new();
                return scope.elementScope;
            }
        }
    };
});
