'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Module
 * @description
 * The Module service.
 */
angular.module('uasApp')
    .factory('Module', function(UasResource) {
        return UasResource('/module', {
            'children': {
                method: 'GET', 
                url: '/module/:id/children',  
                isArray: true
            },
            'studies': {
                method: 'GET', 
                url: '/module/:id/studies',  
                isArray: true
            },
            'generate': {
                method: 'POST',
                url: '/module/generate',
                isArray: false
            },
            'update': {
                method: 'PUT'
            },
            'deleteFull': {
                method: 'DELETE',
                url: '/module/:id/full',
                isArray: false
            }
        });
    });
