'use strict';

/**
 * List based report of changes.
 */
angular.module('uasApp')
    .component('changeReport', {
        bindings: {
            entity: '<'
        },
        templateUrl: 'es6/changes/change.report.html',
        controllerAs: 'changeReportController',
        controller: function (Language, Change, ChangeModal, EntityService, EntityTypes, Study, typeFilter, $uibModal) {
            const changeReportController = this;

            changeReportController.$onInit = function() {
                changeReportController.yearOnly = true;
                changeReportController.showAll = false;
                changeReportController.limit = 100;
                changeReportController.selected = [];

                changeReportController.reference = EntityService.toReference(changeReportController.entity);
                if (changeReportController.reference.type === 'faculty') {
                    changeReportController.facultyId = changeReportController.entity.id;
                } else if (changeReportController.reference.type === 'study') {
                    changeReportController.studyId = changeReportController.entity.id;
                } else {
                    changeReportController.facultyId = changeReportController.entity.facultyId;
                    changeReportController.studyId = changeReportController.entity.studyId;
                }

                loadStudies();
                loadTypes();

                changeReportController.loadData();
                Language.onChange(changeReportController.loadData);
            };

            function loadStudies() {
                if (angular.isDefined(changeReportController.facultyId)) {
                    Study.query({
                        facultyId: changeReportController.facultyId,
                        academicYearId: sessionStorage.academicYear
                    }).$promise.then((studies) => {
                        changeReportController.studies = studies;
                    });
                }
            }

            function loadTypes() {
                EntityTypes.load().then((types) => {
                    changeReportController.entityTypes = types;
                    changeReportController.rootTypes =  _(types).map('context').map('rootType').uniq().map(buildType).sortBy('label').value();
                    changeReportController.entityTypesMapped = _(types).map('context').map('entityType').uniq().map(buildType).sortBy('label').value();
                });
            }

            function buildType(type) {
                return {
                    name: type,
                    label: `${typeFilter(type)} (${type})`
                };
            }

            changeReportController.setShowAll = function(showAll) {
                changeReportController.showAll = showAll;
                changeReportController.loadData();
            };

            changeReportController.setStatus = function(status) {
                if (changeReportController.status === status) {
                    delete changeReportController.status;
                } else {
                    changeReportController.status = status;
                }

                changeReportController.loadData();
            };

            changeReportController.loadAll = function() {
                delete changeReportController.limit;
                changeReportController.loadData();
            };

            changeReportController.loadData = function() {
                changeReportController.parameters = buildParameters();
                changeReportController.loading = true;
                return Change.report(changeReportController.parameters).$promise.then((report) => {
                    changeReportController.report = report;
                }).finally(() => {
                    changeReportController.loading = false;
                });
            };

            function buildParameters() {
                return {
                    rootType: changeReportController.rootType,
                    entityType: changeReportController.entityType,
                    facultyId: changeReportController.facultyId,
                    studyId: changeReportController.studyId,
                    status: changeReportController.status,
                    processTypeId: changeReportController.processTypeId,
                    yearOnly: changeReportController.yearOnly,
                    showAll: changeReportController.showAll,
                    startDate: _.get(changeReportController.dateRange, 'from'),
                    endDate: _.get(changeReportController.dateRange, 'to'),
                    limit: changeReportController.limit,
                    language: Language.get()
                };
            }

            changeReportController.select = function(rows) {
                changeReportController.selected = rows;
            };

            changeReportController.open = function(row) {
                ChangeModal.openRevisions(row.entity.id, changeReportController.loadData);
            };

            changeReportController.changeStatus = function() {
                $uibModal.open({
                    templateUrl: 'es6/changes/change.status.modal.html',
                    controllerAs: 'changeStatusController',
                    controller: function($uibModalInstance, Promises) {
                        const changeStatusController = this;

                        changeStatusController.$onInit = function() {
                            changeStatusController.newStatus = changeReportController.status;
                            changeStatusController.changeIds = _.map(changeReportController.selected, 'id');
                            changeStatusController.message = '';
                        };

                        changeStatusController.submit = function() {
                            const chunks = _.chunk(changeStatusController.changeIds, 25);
                            const requests = _.map(chunks, transition);

                            const progress = Promises.chain(requests);
                            changeStatusController.progress = progress;

                            progress.$promise.finally(() => {
                                changeReportController.loadData();
                                changeStatusController.close();
                            });
                        };

                        function transition(changeIds) {
                            return () => Change.transition({
                                changeIds: changeIds,
                                newStatus: changeStatusController.newStatus,
                                message: changeStatusController.message
                            }).$promise;
                        }

                        changeStatusController.close = function() {
                            $uibModalInstance.dismiss();
                        };
                    }
                });
            };

            changeReportController.propagate = function() {
                $uibModal.open({
                    templateUrl: 'es6/changes/change.propagate.modal.html',
                    controllerAs: 'changePropagateController',
                    controller: function($uibModalInstance, Promises) {
                        const changePropagateController = this;

                        changePropagateController.$onInit = function() {
                            changePropagateController.changeIds = _(changeReportController.selected).map('id').sort().value();
                        };

                        changePropagateController.submit = function() {
                            const requests = _.map(changePropagateController.changeIds, propagate);
                            const progress = Promises.chain(requests);
                            changePropagateController.progress = progress;

                            progress.$promise.finally(() => {
                                changeReportController.loadData();
                                changePropagateController.close();
                            });
                        };

                        function propagate(id) {
                            return () => Change.propagate({ id }, {}).$promise;
                        }

                        changePropagateController.close = function() {
                            $uibModalInstance.dismiss();
                        };
                    }
                });
            };
        }
    });
