'use strict';

angular.module('uasApp')
  .component('uasScheduledActivitiesReport', {
    templateUrl: 'es6/schedule/scheduled/scheduled.activities.report.html',
    controllerAs: 'reportController',
    bindings: {
      facultyId: '<?',
      methodTypeId: '<?',
      periodId: '<?',
      studyId: '<?'
    },
    controller: function (Report, COLUMN_TYPES) {
      const reportController = this;

      reportController.$onInit = function() {
        reportController.columns = [
          { code: 'faculty.code', label: 'Static.Report.ScheduledActivities.FacultyCode', visible: true },
          { code: 'faculty.localName', label: 'Static.Report.ScheduledActivities.FacultyLocalName' },
          { code: 'faculty.englishName', label: 'Static.Report.ScheduledActivities.FacultyEnglishName' },
          { code: 'study.code', label: 'Static.Report.ScheduledActivities.StudyCode' },
          { code: 'study.localName', label: 'Static.Report.ScheduledActivities.StudyLocalName' },
          { code: 'study.englishName', label: 'Static.Report.ScheduledActivities.StudyEnglishName' },
          { code: 'module.code', label: 'Static.Report.ScheduledActivities.ModuleCode', visible: true },
          { code: 'module.localName', label: 'Static.Report.ScheduledActivities.ModuleLocalName' },
          { code: 'module.englishName', label: 'Static.Report.ScheduledActivities.ModuleEnglishName' },
          { code: 'period.localName', label: 'Static.Report.ScheduledActivities.PeriodLocalName' },
          { code: 'period.englishName', label: 'Static.Report.ScheduledActivities.PeriodEnglishName' },
          { code: 'methodType.category.externalId', label: 'Static.Report.ScheduledActivities.MethodCategoryExternalId' },
          { code: 'methodType.externalId', label: 'Static.Report.ScheduledActivities.MethodTypeExternalId' },
          { code: 'week', label: 'Static.Report.ScheduledActivities.Week', visible: true },
          { code: 'remark', label: 'Activity.Remark', visible: true },
          { code: 'activityExternalId', label: 'Static.Report.ScheduledActivities.ActivityExternalId' },
          { code: 'activityName', label: 'Static.Report.ScheduledActivities.ActivityName', visible: true },
          { code: 'activityRemark', label: 'Static.Report.ScheduledActivities.ActivityRemark' },
          { code: 'duration', label: 'Static.Report.ScheduledActivities.Duration', visible: true },
          { code: 'totalExpectedParticipants', label: 'Static.Report.ScheduledActivities.TotalExpectedParticipants' },
          { code: 'numberOfGroups', label: 'Static.Report.ScheduledActivities.NumberOfGroups' },
          { code: 'groupNumber', label: 'Static.Report.ScheduledActivities.GroupNumber' },
          { code: 'minGroupSize', label: 'Static.Report.ScheduledActivities.MinGroupSize' },
          { code: 'maxGroupSize', label: 'Static.Report.ScheduledActivities.MaxGroupSize' },
          { code: 'parallelScheduling', label: 'Static.Report.ScheduledActivities.ParallelScheduling' },
          { code: 'roomType.localName', label: 'Static.Report.ScheduledActivities.RoomLocalName' },
          { code: 'roomType.englishName', label: 'Static.Report.ScheduledActivities.RoomEnglishName' },
          { code: 'facilities', label: 'Static.Report.ScheduledActivities.Facilities' },
          { code: 'suggestedDay', label: 'Activity.SuggestedDay', valueType: COLUMN_TYPES.ENUM },
          { code: 'suggestedTime', label: 'Activity.SuggestedTime' },
          { code: 'assignmentType.code', label: 'Static.Report.ScheduledActivities.Assignation' },
          { code: 'personExternalIds', label: 'Static.Report.ScheduledActivities.VuNet' },
          { code: 'personNames', label: 'Static.Report.ScheduledActivities.Person' },
          { code: 'vacancies', label: 'Static.Report.ScheduledActivities.Vacancies' }
        ];
      };

      reportController.getData = function (pageable, total) {
        const params = _.extend(pageable, {
          academicYearId: sessionStorage.academicYear,
          facultyId: reportController.facultyId,
          methodTypeId: reportController.methodTypeId,
          periodId: reportController.periodId,
          studyId: reportController.studyId,
          total: total
        });

        return Report.tracks(params).$promise;
      };

    }
  });
