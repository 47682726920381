'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:trackEdit
 * @description
 */
angular.module('uasApp').component('trackEdit', {
  bindings: {
    track: '<',
    activity: '<',
    persons: '<',
    vacancies: '<',
    weeks: '<',
    onSubmit: '&',
    onCancel: '&'
  },
  templateUrl: 'es6/schedule/track/track.edit.html',
  controllerAs: 'trackEditController',
  controller: function (Element, Parameter) {
    const trackEditController = this;

    trackEditController.$onInit = function() {
        Parameter.load().then(() => {
            const calendarWeeks = Parameter.getParameterAsBoolean('calendar.weeks');
            trackEditController.weeks = _.map(trackEditController.weeks, (week) => {
                return {
                    number: week.week,
                    label: calendarWeeks ? week.yearWeek : week.week,
                    selected: _.inRange(week.week, trackEditController.track.week.week, trackEditController.track.lastWeek.week + 1)
                };
            });
        });

        const groupNumbers = _.range(1, trackEditController.activity.numberOfGroups + 1);
        trackEditController.groups = _.map(groupNumbers, (groupNumber) => {
            return {
                number: groupNumber,
                selected: groupNumber === trackEditController.track.group
            };
        });

        const periodId = _.get(trackEditController.activity, 'period.id');
        Element.getByType('AssignmentType', periodId).then((assignmentTypes) => {
            trackEditController.assignmentTypes = assignmentTypes;
        });
    };

    trackEditController.onChange = function(track) {
        trackEditController.track = _.extend(trackEditController.track, track);
    };

    trackEditController.onSelect = function(entry) {
        entry.selected = entry.selected !== true;
    };

    trackEditController.onSelectAll = function(entries) {
        const selected = _.every(entries, { selected: true });
        _.forEach(entries, (entry) => {
            entry.selected = selected !== true;
        });
    };

    trackEditController.cancel = function() {
        trackEditController.onCancel();
    };

    trackEditController.submit = function() {
        const data = {
            body: buildBody(trackEditController.track),
            groups: _(trackEditController.groups).filter({ selected: true }).value(),
            weeks: _(trackEditController.weeks).filter({ selected: true }).value()
        };

        trackEditController.onSubmit(data);
    };

    function buildBody(track) {
        return _.extend(track, {
            personIds: _.map(track.persons, 'id'),
            vacancyIds: _.map(track.vacancies, 'id')
        });
    }
  }
});
