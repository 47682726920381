'use strict';

/**
 * The entity types.
 */
angular.module('uasApp').constant('ENTITY_TYPES', {
        'additional': {
            icon: 'fa fa-book',
            index: 16
        },
        'advice': {
            icon: 'fa fa-comments-o',
            index: 37
        },
        'assignment': {
            icon: 'fa fa-user',
            index: 40
        },
        'assessment': {
            icon: 'fa fa-legal',
            index: 35
        },
        'appraisal': {
            icon: 'fa fa-check',
            index: 36
        },
        'award-qualification': {
            icon: 'fa fa-newspaper-o'
        },
        'organisation': {
            icon: 'fa fa-university',
            index: 28,
            children: ['study', 'module-group', 'module']
        },
        'description': {
            icon: 'fa fa-align-left',
            index: 9
        },
        'credits': {
            icon: 'fa fa-trophy'
        },
        'element': {
            icon: 'fa fa-tag'
        },
        'effort': {
            icon: 'fa fa-briefcase'
        },
        'role-type': {
            icon: 'fa fa-key'
        },
        'calendar': {
            icon: 'fa fa-calendar'
        },
        'period': {
            icon: 'fa fa-calendar'
        },
        'faculty': {
            icon: 'fa fa-building-o',
            index: 1,
            children: ['organisation', 'study', 'module-group', 'module']
        },
        'institution': {
            icon: 'fa fa-building-o',
            index: 0
        },
        'module': {
            icon: 'fa fa-paperclip',
            index: 4
        },
        'module-additional': {
            icon: 'fa fa-book',
            index: 13
        },
        'module-group': {
            icon: 'fa fa-th-large',
            index: 3,
            children: ['module']
        },
        'module-group-additional': {
            icon: 'fa fa-book',
            index: 12
        },
        'module-group-module': {
            icon: 'fa fa-plug',
            index: 8
        },
        'module-group-module-group': {
            icon: 'fa fa-plug',
            index: 7
        },
        'method': {
            icon: 'fa fa-suitcase',
            index: 11
        },
        'method-schema': {
            icon: 'fa fa-suitcase',
            index: 29
        },
        'method-type': {
            icon: 'fa fa-suitcase',
            index: 29
        },
        'module-resource': {
            icon: 'fa fa-tag',
            index: 32
        },
        'module-group-schedule': {
            icon: 'fa fa-clock-o'
        },
        'module-schedule': {
            icon: 'fa fa-clock-o',
            index: 22
        },
        'module-week': {
            icon: 'fa fa-clock-o',
            index: 23
        },
        'activity': {
            icon: 'fa fa-clock-o',
            index: 24
        },
        'activity-planning': {
            icon: 'fa fa-clock-o',
            index: 25
        },
        'activity-week': {
            icon: 'fa fa-clock-o',
            index: 25
        },
        'track': {
            icon: 'fa fa-clock-o',
            index: 26
        },
        'relation': {
            icon: 'fa fa-user',
            index: 10
        },
        'offering': {
            icon: 'fa fa-calendar',
            index: 5
        },
        'person': {
            icon: 'fa fa-user'
        },
        'specification': {
            icon: 'fa fa-book'
        },
        'team': {
            icon: 'fa fa-users'
        },
        'study': {
            icon: 'fa fa-graduation-cap',
            index: 2,
            children: ['module-group', 'module']
        },
        'capacity': {
            icon: 'fa fa-users',
            index: 17
        },
        'qualification': {
            icon: 'fa fa-newspaper-o',
            index: 19
        },
        'study-module-group': {
            icon: 'fa fa-plug',
            index: 6
        },
        'task': {
            icon: 'fa fa-briefcase',
            index: 20
        },
        'academic-year': {
            icon: 'fa fa-globe',
            children: ['faculty', 'organisation', 'study', 'module-group', 'module']
        },
        'link': {
            icon: 'fa fa-external-link',
            index: 31
        },
        'rule': {
            icon: 'fa fa-shield',
            index: 32
        },
        'item': {
            icon: 'fa fa-list-ul',
            index: 33
        },
        'asset': {
            icon: 'fa fa-book',
            index: 34
        },
        'delivery': {
            icon: 'fa fa-truck',
            index: 35
        },
        'cost-division': {
            icon: 'fa fa-pie-chart',
            index: 36
        },
        'subject': {
            icon: 'fa fa-lightbulb-o',
            index: 40
        },
        'objective': {
            icon: 'fa fa-bullseye',
            index: 41
        },
        'document': {
            icon: 'fa fa-file-text-o',
            index: 42
        }
    });
