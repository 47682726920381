'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Track
 * @description
 * The Track service.
 */
angular.module('uasApp')
    .factory('Track', function(UasResource, Assignment) {

        const resource = UasResource('/tracks', {
            durations: {
                url: '/track-duration',
                method: 'GET',
                isArray: true
            }
        });

        function reduce(tracks) {
            const reduced = _(tracks).sortBy(['group', 'week.period', 'week.week']).reduce((accumulator, track) => {
                const key = {
                    group: track.group,
                    assignmentTypeId: track.assignmentTypeId,
                    personIds: _(track.persons || []).map('id').sort().value()
                };
    
                const lastKey = accumulator.lastKey;
                const total = accumulator.total;
    
                if (_.isEqual(key, lastKey)) {
                    _.last(total).push(track);
                } else {
                    total.push([track]);
                }
    
                return {
                    lastKey: key,
                    total: total
                };
            }, {
                total: []
            });
    
            return _(reduced.total).map((partition) => {
                const first = _.head(partition);
                const last = _.last(partition);
                first.lastWeek = last.week;
                return first;
            }).value();
        }

        function getTracks({ entityId, periodId }) {
            return resource.query({ entityId, periodId }).$promise.then((tracks) => {
                return _.forEach(tracks, (track) => {
                    tracks.vacancies = _.map(track.vacancies, setLabel);
                });
            });
        }

        function getVacancies(tracks, vacancies) {
            return _(tracks)
                .flatMap('vacancies')
                .concat(vacancies)
                .filter(angular.isDefined)
                .uniqBy('id')
                .map(setLabel)
                .sortBy('label')
                .value();
        }

        function setLabel(vacancy) {
            return _.extend(vacancy, { label: Assignment.getLabel(vacancy) });
        }
    
        return _.extend(resource, { reduce, getTracks, getVacancies });

    });
