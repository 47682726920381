'use strict';

angular.module('uasApp').component('entityOptions', {
  bindings: {
    entities: '<?', // must have a numeric id
    type: '<?',
    tagsPlacement: '@?',
    toggleLabel: '@?',
    toggleIcon: '@?',
    dropdownAlign: '@?',
    dropdownClasses: '@?',
    dropdownInModal: '<?',
    dropdownIsOpen: '<?',
    dropdownLabel: '@?',
    dropdownHasActions: '<?',
    onToggleDropdown: '&?',
    groups: '<?',
    searchLabel: '@?',
    availableOptionsLabel: '@?',
    selectedOptionsLabel: '@?',
    onChange: '&?',
    label: '@?',
    selectId: '@',
    showPrefix: '<?',
    prefixProperty: '@?',
    classes: '@?',
    uasDisabled: '<?',
    uasRequired: '<?',
    isReadOnly: '<?',
    isReorderable: '<?',
    viewClasses: '@?',
    displayType: '<?',
    multipleValues: '<?',
    arrayModel: '<?', // Indicates if the ng-model is an array or a single value. Default = false
    filter: '<?', // Option filter expression
    filterValues: '<?', // Option filter function
    excludeIds: '<?', // Ids that should be excluded from options
    minItems: '<?',
    maxItems: '<?',
    formatItem: '&?',
    sort: '<?', // Array of sort properties, for example ['name', 'sequence']
    selectedSort: '<?'
  },
  transclude: true,
  templateUrl: 'es6/app/entity/entity.options.html',
  controllerAs: 'optionsController',
  controller: function ($q, entityTranslateFilter, i18nFilter, Changes, Entity, Parameter) {
    const optionsController = this;

    optionsController.$onChanges = function (changes) {
      setDisplayType();

      if (Changes.hasChanged(changes, ['type', 'filter', 'entities']) || optionsController.type) {
        getEntities().then((entities) => {
          optionsController.options = filterValues(entities);
          setDisplayType();
        });
      }
    };

    function getEntities() {
      if (angular.isDefined(optionsController.entities)) {
        return $q.resolve(optionsController.entities);
      }
      if (angular.isUndefined(optionsController.type)) {
        return $q.resolve([]);
      }

      return Entity.query({
        type: optionsController.type,
        filter: optionsController.filter,
        academicYearId: sessionStorage.academicYear
      }).$promise;
    }

    function setDisplayType() {
      optionsController.displayType_ = optionsController.displayType;
      if (!optionsController.displayType) {
        Parameter.load().then(() => {
          const size = (optionsController.options || []).length;
          const limit = Parameter.getParameterAsInt('select.search_from', 100);
          optionsController.displayType_ = size >= limit ? 'DROPDOWN_FILTER' : 'DROPDOWN';
        });
      }
    }

    optionsController.formatValue = function (entity) {
      if (_.isNumber(entity)) {
        const found = _.find(optionsController.options, { id: entity });
        return optionsController.formatValue(found);
      }

      if (_.isFunction(optionsController.formatItem)) {
        const name = optionsController.formatItem({ entity });
        if (!_.isEmpty(name)) {
          return name;
        }
      }

      const name = getName(entity);
      const prefix = getPrefix(entity);

      if (_.isEmpty(name)) {
        return prefix;
      } else if (_.isEmpty(prefix) || prefix === name || optionsController.showPrefix === false) {
        return name;
      } else {
        return `${prefix} - ${name}`;
      }
    };

    optionsController.setNames = function (entities) {
      _.each(entities, (entity) => {
        entity.name = optionsController.formatValue(entity);
      });
    };

    function getName(entity) {
      if (_.isEmpty(optionsController.label)) {
        return entityTranslateFilter(entity) || '';
      } else {
        let label = _.get(entity, optionsController.label, '');
        if (_.isArray(label)) {
          label = i18nFilter(label) || '';
        }
        return label;
      }
    }

    function getPrefix(entity) {
      if (_.isEmpty(optionsController.prefixProperty)) {
        return '';
      }

      return _.get(entity, optionsController.prefixProperty, '');
    }

    function filterValues(options) {
      if (_.isFunction(optionsController.filterValues)) {
        return _.filter(options, optionsController.filterValues);
      }

      return options;
    }
  }
});
