'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:trackReportPage
 * @description
 * The trackReportPage component.
 */
angular.module('uasApp').component('trackReportPage', {
  templateUrl: 'es6/schedule/track/track.report.page.html',
  bindings: {
    entity: '<',
    process: '<',
    page: '<',
    operations: '<'
  },
  controllerAs: 'trackReportPageController',
  controller: function (Offering) {
    const trackReportPageController = this;

    trackReportPageController.$onInit = function () {
      const type = _.result(trackReportPageController.entity, 'self.type');

      if (type === 'module') {
        loadPeriods(trackReportPageController.entity.id);
      }
    };

    function loadPeriods(moduleId) {
      trackReportPageController.loading = true;
      Offering.periods({
        entityType: 'module',
        entityId: moduleId,
        periodId: _.result(trackReportPageController.process, 'period.id')
      }).$promise.then((periods) => {
        trackReportPageController.periods = periods;
      }).finally(() => {
        trackReportPageController.loading = false;
      });
    }
  }
});