'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Activity
 * @description
 * The Activity service.
 */
angular.module('uasApp').factory('Activity', function(UasResource) {
    const instance = UasResource('/activities', {
        batch: {
            url: '/activities/batch-update',
            method: 'POST'
        },
        report: {
            url: '/activity-report',
            method: 'GET'
        },
        reset: {
            url: '/activities/reset',
            method: 'POST'
        },
        generate: {
            url: '/activities/generate',
            method: 'POST'
        },
        validate: {
            url: '/activities/validate',
            method: 'GET'
        }
    });

    function build(activity, weeks) {
        const result = angular.copy(activity);
        result.plannings = getPlannings(activity.plannings, weeks);
        return result;
    }

    function getPlannings(plannings, weeks) {
        const active = _.filter(weeks, { active: true });

        // Reuse existing weeks where possible
        const existing = _.map(plannings, (planning) => {
            return _.extend(planning, {
                active: _.some(active, { week: planning.week })
            }); 
        });

        const created = _.filter(active, (week) => {
            return !_.some(plannings, { week: week.week });
        });

        return _.concat(existing, created);
    }

    function getDuration(activity) {
        let duration = activity.calculatedDuration;
        if (angular.isUndefined(duration)) {
            duration = activity.duration || 0;
        }
        return duration;
    }

    function getWeeks(weeks, activity) {
        const plannings = _.get(activity, 'plannings', []);

        return _.map(weeks, (week) => {
            const result = angular.copy(week);
            return _.extend(result, {
                active: _.some(plannings, { week: week.week })
            });
        });
    }
    return _.extend(instance, { build, getDuration, getWeeks });
});
