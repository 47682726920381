'use strict';

angular.module('uasApp')
  .component('uasEditModuleGroup', {
      bindings: {
          moduleGroup: '<',
          entityType: '<?',
          operations: '<',
          onCancel: '&',
          onSave: '&',
          workflowMode: '<?',
          extensions: '<?'
      },
      templateUrl: 'es6/planboard/edit/edit.module.group.html',
      controllerAs: 'editModuleGroupController',
      controller: function($q, ModuleGroup, Message, translateFilter, CustomField) {
        const editModuleGroupController = this;

        editModuleGroupController.$onInit = function () {
            editModuleGroupController.saveCallbacks = [];

            editModuleGroupController.loading = true;
            CustomField.query({
                rootType: 'module-group',
                entityType: 'module-group'
            }).$promise.then((fields) => {
                editModuleGroupController.fields = fields;
            }).finally(() => {
                editModuleGroupController.loading = false;
            });
        };

        editModuleGroupController.save = function() {
            updateModuleGroup()
                .then(() => $q.all(executeSaveFunctions()))
                .then(() => {
                    editModuleGroupController.onSave();
                    Message.addSuccess(translateFilter('Static.Message.DataSaved'));
                });
        };

        function updateModuleGroup() {
            return ModuleGroup.update({
                comment: editModuleGroupController.comment,
                content: editModuleGroupController.moduleGroup
            }).$promise;
        }

        function executeSaveFunctions() {
            return _(editModuleGroupController.saveCallbacks)
                .filter((saveFn) => _.isFunction(saveFn))
                .map((saveFn) => $q.resolve(saveFn(editModuleGroupController.moduleGroup, editModuleGroupController.comment)))
                .value();
        }

        editModuleGroupController.cancel = function() {
            editModuleGroupController.onCancel();
        };
      }
    });
