'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:trackAssign
 * @description Displays a list of tracks to which you can assign teachers.
 */
angular.module('uasApp').component('trackAssign', {
  bindings: {
    module: '<',
    period: '<',
    operations: '<',
    operationsToEdit: '<?'
  },
  templateUrl: 'es6/schedule/track/track.assign.html',
  controllerAs: 'trackAssignController',
  controller: function ($q, Activity, AvailabilityModal, Assignment, Module, Language, Track, Message, Relation, Pageable, Offering, Parameter,
    SecurityService, configDateFilter, entityTranslateFilter) {

    const trackAssignController = this;

    trackAssignController.$onInit = function () {
      trackAssignController.editable = SecurityService.isAllowed(trackAssignController.operationsToEdit, trackAssignController.operations);
      trackAssignController.pageable = Pageable.of({
        order: 'week.startDate'
      });

      trackAssignController.headers = [
        { property: 'week.period', label: 'Static.Tab.Schedule.Teachers.Period' },
        { property: 'week.label', label: 'Static.Tab.Schedule.Teachers.Week' },
        { property: 'group', label: 'Static.Tab.Schedule.Teachers.Group' },
        { property: 'week.startDate', label: 'Static.Tab.Schedule.Teachers.Date' },
        { property: 'activity.displayType', label: 'Static.Tab.Schedule.Teachers.ActivityType' },
        { property: 'activity.code', label: 'Static.Tab.Schedule.Teachers.ActivityCode' },
        { property: 'activity.duration', search: false, label: 'Static.Tab.Schedule.Teachers.Duration' }
      ];

      Module.children({
        id: trackAssignController.module.id
      }).$promise.then((children) => {
        trackAssignController.children = children;
        loadData();
      });

      Language.onChange(updateRows);
    };

    function loadData() {
      const childrenIds = _.map(trackAssignController.children, 'id');

      trackAssignController.loading = true;
      $q.all([
        Activity.query({
          entityId: _.concat(trackAssignController.module.id, childrenIds),
          periodId: trackAssignController.period.id
        }).$promise,
        Track.getTracks({
          entityId: _.concat(trackAssignController.module.id, childrenIds),
          periodId: trackAssignController.period.id
        }),
        Offering.weeks({
          entityType: 'module',
          entityId: trackAssignController.module.id,
          periodId: trackAssignController.period.id,
          exceedPeriod: true
        }).$promise,
        Relation.teachers({
          entityType: 'module',
          entityId: trackAssignController.module.id,
          periodId: trackAssignController.period.id
        }).$promise,
        Relation.vacancies({
          entityType: 'module',
          entityId: trackAssignController.module.id,
          periodId: trackAssignController.period.id
        }).$promise,
        loadTeacherHours(),
        Parameter.load()
      ]).then(([activities, tracks, weeks, teachers, vacancies]) => {
        trackAssignController.vacancies = Track.getVacancies(tracks, vacancies);
        trackAssignController.persons = _(tracks)
          .flatMap('persons')
          .concat(teachers)
          .filter(angular.isDefined)
          .uniqBy('id')
          .value();

        const calendarWeeks = Parameter.getParameterAsBoolean('calendar.weeks');
        trackAssignController.tracks = _(activities)
          .map((activity) => {
            const extended = _(activity.plannings).map((planning) => {
              return _(tracks).filter({ planning: planning.id }).map((track) => {
                const week = _.find(weeks, { week: planning.week }) || { week: planning.week };
                week.label = calendarWeeks ? week.yearWeek : week.week;

                return _.extend(track, {
                  week,
                  activity
                });
              }).value();
            }).flatten().value();

            activity.tracks = extended;
            return activity;
          })
          .map((activity) => activity.tracks)
          .flatten()
          .sortBy(['week.period', 'week.week', 'activity.type.id', 'activity.code'])
          .value();

        updateRows();

        trackAssignController.duration = _.sumBy(trackAssignController.tracks, 'activity.duration');
        trackAssignController.filtered = trackAssignController.tracks;
      }).finally(() => {
        trackAssignController.loading = false;
      });
    }

    function updateRows() {
      _.forEach(trackAssignController.tracks, (track) => {
        track.empty = _.isEmpty(track.persons) && _.isEmpty(track.vacancies);
        formatActivityType(track.activity);
        formatStartDate(track.week);
      });
    }

    function formatActivityType(activity) {
      if (activity.type) {
        activity.displayType = entityTranslateFilter(activity.type);
      }
    }

    function formatStartDate(week) {
      if (week) {
        week.displayStartDate = configDateFilter(week.startDate);
      }
    }

    function loadTeacherHours() {
      return Track.durations({
        entityType: 'module',
        entityId: trackAssignController.module.id,
        periodId: trackAssignController.period.id
      }).$promise.then((teachers) => {
        trackAssignController.teachers = _.orderBy(teachers, ['hours', 'name'], ['desc', 'asc']);
        trackAssignController.teacherHours = _.sumBy(teachers, 'hours');
      });
    }

    trackAssignController.showAvailability = function (availability) {
      AvailabilityModal.open({
        assignmentId: availability.assignment.id
      });
    };

    trackAssignController.onChange = function (track) {
      return store(track).then(() => {
        afterSave();
      });
    };

    trackAssignController.removePerson = function (track, person, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      _.remove(track.persons, { id: person.id });
      trackAssignController.onChange(track);
    };

    trackAssignController.removeVacancy = function (track, vacancy, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      _.remove(track.vacancies, { id: vacancy.id });
      trackAssignController.onChange(track);
    };

    function afterSave() {
      Message.onSaved();
      loadTeacherHours();
      updateRows();
    }

    trackAssignController.copy = function (template, $index) {
      const remainder = trackAssignController.filtered.slice($index + 1, trackAssignController.filtered.length + 1);
      const promises = _.map(remainder, (track) => {
        track.persons = angular.copy(template.persons);
        track.vacancies = angular.copy(template.vacancies);
        track.assignmentTypeId = template.assignmentTypeId;
        return store(track);
      });

      $q.all(promises).then(() => {
        afterSave();
      });
    };

    function store(track) {
      return Track.store({
        id: track.id,
        personIds: _.map(track.persons, 'id'),
        vacancyIds: _.map(track.vacancies, 'id'),
        assignmentTypeId: track.assignmentTypeId
      }).$promise;
    }

    trackAssignController.onSort = function () {
      const direction = trackAssignController.pageable.reverse ? 'desc' : 'asc';
      trackAssignController.filtered = _.orderBy(trackAssignController.filtered, trackAssignController.pageable.order, direction);
    };

    trackAssignController.filter = function () {
      trackAssignController.filtered = _.filter(trackAssignController.tracks, (track) =>
        _.every(trackAssignController.search, (value, key) => matches(value, _.get(track, key)))
      );
    };

    function matches(search, value) {
      if (isEmpty(search.value)) {
        return true;
      }
      if (search.exact) {
        return value === search.value;
      }
      return _.includes(_.toLower(value), _.toLower(search.value));
    }

    function isEmpty(value) {
      return angular.isUndefined(value) || value === null || value === '';
    }

    trackAssignController.getFilterValues = function (property) {
      return _(trackAssignController.tracks)
        .map((track) => _.get(track, property))
        .map((value) => mapValue(value))
        .uniqBy('display')
        .value();
    };

    function mapValue(value) {
      if (value instanceof Date) {
        return { value, display: configDateFilter(value) };
      }
      return { value, display: '' + value };
    }

    trackAssignController.clear = function (property) {
      delete trackAssignController.search[property];
      trackAssignController.filter();
    };

    trackAssignController.selectFilter = function (property) {
      trackAssignController.search[property].exact = true;
      trackAssignController.filter();
    };

    trackAssignController.formatValue = function (model) {
      if (model instanceof Date) {
        return configDateFilter(model);
      }
      return model;
    };
  }
});