'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasModuleSubGroupAdd
 * @description
 * # uasModuleSubGroupAdd
 * Component for adding a module(group) to a module group.
 */
angular.module('uasApp').component('uasModuleSubGroupAdd', {
  bindings: {
    studyId: '<',
    parent: '<',
    onAdd: '&',
    uibModalInstance: '<',
    operations: '<',
    operationsToEdit: '<?',
    workflowMode: '<?'
  },
  templateUrl: 'es6/planboard/add/module.subgroup.add.html',
  controllerAs: 'moduleSubGroupAddController',
  controller: function ($q, CustomField, EntityType, Tabs, Message, ModuleGroup, GroupType, Type, translateFilter, entityTranslateFilter, CreateManager) {
    const moduleSubGroupAddController = this;

    moduleSubGroupAddController.$onInit = function () {
      moduleSubGroupAddController.saveCallbacks = [];

      loadData().finally(() => {
        buildTabs();
      });
    };

    function loadData() {
      moduleSubGroupAddController.loading = true;
      return $q.all([
        CustomField.query({
          rootType: 'module-group',
          entityType: 'module-group'
        }).$promise,
        CustomField.query({
          rootType: 'module-group',
          entityType: 'module-group-module-group'
        }).$promise,
        EntityType.get({
          rootType: 'module-group',
          entityType: 'module-group-module-group'
        }).$promise,
        GroupType.query().$promise,
        getExcludeTypeIds()
      ]).then(([groupFields, fields, entityType, groupTypes, excludeTypeIds]) => {
        moduleSubGroupAddController.groupFields = groupFields;
        moduleSubGroupAddController.fields = fields;
        moduleSubGroupAddController.fields_ = _.keyBy(fields, 'name');
        moduleSubGroupAddController.includeTypes = EntityType.getIncludeTypes(entityType);

        moduleSubGroupAddController.excludeTypeIdsForCreate = excludeTypeIds.create;
        moduleSubGroupAddController.createTypes = Type.getAllowed(groupTypes, moduleSubGroupAddController.includeTypes, excludeTypeIds.create);

        moduleSubGroupAddController.extensions = {
          typeId: {
            options: moduleSubGroupAddController.createTypes
          }
        };

        moduleSubGroupAddController.excludeTypeIdsForReuse = excludeTypeIds.reuse;
        moduleSubGroupAddController.existingTypes = Type.getAllowed(groupTypes, moduleSubGroupAddController.includeTypes, excludeTypeIds.reuse);
      }).finally(() => {
        moduleSubGroupAddController.loading = false;
      });
    }

    function getExcludeTypeIds() {
      return GroupType.excluded({
        studyId: moduleSubGroupAddController.studyId,
        groupId: moduleSubGroupAddController.parent.id
      }).$promise;
    }

    function buildTabs() {
      const tabs = [{
        title: 'Static.Modal.ModuleGroupAdd.New.Tab',
        route: 'study.cost.add-new-sub-group',
        content: 'add-new-sub-group.html',
        visible: moduleSubGroupAddController.createTypes.length > 0
      }, {
        title: 'Static.Modal.ModuleGroupAdd.Existing.Tab',
        route: 'study.cost.add-existing-sub-group',
        content: 'add-existing-sub-group.html',
        visible: moduleSubGroupAddController.existingTypes.length > 0
      }];

      Tabs.build(tabs, [], 'study').then((createdTabs) => {
        moduleSubGroupAddController.tabs = createdTabs;
        updateTabs();
      });
    }

    function updateTabs() {
      _.each(moduleSubGroupAddController.tabs, function (tab) {
        tab.active = Tabs.isActive(tab);
        if (tab.active) {
          moduleSubGroupAddController.tab = tab;
        }
      });
    }

    moduleSubGroupAddController.newGroup = {
      facultyId: moduleSubGroupAddController.parent.facultyId,
      academicYearId: moduleSubGroupAddController.parent.academicYearId,
      studyId: moduleSubGroupAddController.studyId,
      self: { type: 'module-group' }
    };

    moduleSubGroupAddController.search = function (parameters) {
      parameters.groupId = moduleSubGroupAddController.parent.id;
      parameters.excludeTypeIds = moduleSubGroupAddController.excludeTypeIdsForReuse;

      return ModuleGroup.search(parameters).$promise;
    };

    moduleSubGroupAddController.onSelect = function (groups) {
      moduleSubGroupAddController.groups = groups;
    };

    function getEntityName (entity) {
      return `<strong>${entityTranslateFilter(entity)}</strong>`;
    }

    function getParentGroupName (parent) {
      return `<strong>${entityTranslateFilter(parent, 'localName', 'englishName')}</strong>`;
    }

    /**
     * Adds module groups to the parent.
     */
    moduleSubGroupAddController.add = function () {
      _.forEach(moduleSubGroupAddController.groups, add);
      moduleSubGroupAddController.uibModalInstance.close();
    };

    function add(data) {
      const group = angular.copy(data);
      group.required = moduleSubGroupAddController.required;

      moduleSubGroupAddController.onAdd({
        group: group,
        parent: moduleSubGroupAddController.parent,
        message: moduleSubGroupAddController.message
      });

      Message.addSuccess(translateFilter('Static.Message.AddedGroupToTarget', {
        group: getEntityName(group),
        target: getParentGroupName(moduleSubGroupAddController.parent)
      }));
    }

    /**
     * Adds a new group to the study.
     */
    moduleSubGroupAddController.addNew = function (group, message) {
      ModuleGroup.generate({
        message: message,
        moduleGroup: group
      }).$promise.then((result) => {
        group.id = result.entities[0].entity.id;
        group.self.id = group.id;
        return $q.all(executeSaveFunctions(group)).then(() => group);
      }).then(() => {
        moduleSubGroupAddController.onCreate(group);
      });
    };

    moduleSubGroupAddController.onCreate = function(group) {
      CreateManager.onCreate(group, () => onAddSuccess(group));
    };

    function executeSaveFunctions(group) {
      return _(moduleSubGroupAddController.saveCallbacks)
        .filter((saveFn) => _.isFunction(saveFn))
        .map((saveFn) => $q.resolve(saveFn(group, moduleSubGroupAddController.message)))
        .value();
    }

    function onAddSuccess(group) {
      moduleSubGroupAddController.onAdd({
        group: group,
        parent: moduleSubGroupAddController.parent,
        message: moduleSubGroupAddController.message
      });

      moduleSubGroupAddController.uibModalInstance.close(Message.addSuccess(translateFilter('Static.Message.NewAddedGroupToTarget', {
        modulegroup: getEntityName(group),
        target: getParentGroupName(moduleSubGroupAddController.parent)
      })));
    }

    /**
     * Closes the modal
     */
    moduleSubGroupAddController.cancel = function () {
      moduleSubGroupAddController.uibModalInstance.close();
    };
  }
});
