'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:trackPage
 * @description
 * The trackPage component.
 */
angular.module('uasApp').component('trackPage', {
    templateUrl: 'es6/schedule/track/track.page.html',
    bindings: {
        entity: '<',
        page: '<',
        process: '<',
        workflow: '<?',
        flow: '<',
        isReadOnly: '<?',
        operations: '<'
    },
    controllerAs: 'trackPageController',
    controller: function(Offering, Page) {
        const trackPageController = this;

        trackPageController.$onInit = function() {
            const type = _.result(trackPageController.entity, 'self.type');
            if (type === 'module') {
                loadPeriods(trackPageController.entity.id);
            }

            trackPageController.readOnly = trackPageController.isReadOnly || Page.getParameterAsBoolean(trackPageController.page, 'readOnly', false);
            trackPageController.operationsToEdit = angular.isDefined(trackPageController.workflow) ? ['EDIT_SCHEDULE_WORKFLOW'] : ['EDIT_SCHEDULE'];
        };

        function loadPeriods(moduleId) {
            trackPageController.loading = true;
            Offering.periods({
                entityType: 'module',
                entityId: moduleId,
                periodId: _.result(trackPageController.process, 'period.id')
            }).$promise.then((periods) => {
                trackPageController.periods = _.sortBy(periods, ['start', 'end', 'code']);
            }).finally(() => {
                trackPageController.loading = false;
            });
        }
    }
});
