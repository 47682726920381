'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:trackReport
 * @description
 */
angular.module('uasApp').component('trackReport', {
  bindings: {
    module: '<',
    period: '<'
  },
  templateUrl: 'es6/schedule/track/track.report.html',
  controllerAs: 'trackReportController',
  controller: function ($q, Activity, Module, Track, Offering, Parameter) {
    const trackReportController = this;

    trackReportController.$onInit = function() {
        Module.children({
            id: trackReportController.module.id
        }).$promise.then((children) => {
            trackReportController.children = children;
            loadData();
        });
    };

    function loadData() {
        const childrenIds = _.map(trackReportController.children, 'id');

        trackReportController.loading = true;
        $q.all([
            Activity.query({
                entityId: _.concat(trackReportController.module.id, childrenIds),
                periodId: trackReportController.period.id
            }).$promise,
            Track.query({
                entityId: _.concat(trackReportController.module.id, childrenIds),
                periodId: trackReportController.period.id
            }).$promise,
            Offering.weeks({
                entityType: 'module',
                entityId: trackReportController.module.id,
                periodId: trackReportController.period.id
            }).$promise,
            Parameter.load()
        ]).then(([activities, tracks, weeks]) => {
            const types = _(activities).map('type').uniqBy('id').sortBy('sequence').value();

            const calendarWeeks = Parameter.getParameterAsBoolean('calendar.weeks');
            _.forEach(weeks, (week) => {
                week.label = calendarWeeks ? week.yearWeek : week.week;
            });

            trackReportController.types = _.map(types, (type) => {
                type.activities = _(activities).filter((activity) => activity.type.id === type.id).sortBy('name').map((activity) => {
                    const extended = _(activity.plannings).map((planning) => {
                        const week = _.find(weeks, { week: planning.week });
                        return _(tracks).filter({ planning: planning.id }).map((track) => {
                            return _.extend(track, { week });
                        }).value();
                    }).flatten().value();

                    activity.tracks = Track.reduce(extended);
                    return activity;
                }).value();

                return type;
            });
        }).finally(() => {
            trackReportController.loading = false;
        });
    }
  }
});