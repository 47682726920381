'use strict';

angular.module('uasApp').component('activityCard', {
  templateUrl: 'es6/schedule/activity/card/activity.card.html',
  bindings: {
    module: '<',
    period: '<',
    operations: '<',
    categoryId: '<?',
    showScheduleWeeks: '@?',
    isReadOnly: '<?',
    isWorkflow: '<?',
    onValidate: '&'
  },
  controllerAs: 'activityCardController',
  controller: function ($q, Activity, ActivityManager, Module, SecurityService, Dates, CustomField, Message, Offering) {
    const MAX_PER_TABLE = 3;

    const activityCardController = this;

    activityCardController.$onInit = function () {
      const operationsToEdit = activityCardController.isWorkflow ? 'EDIT_SCHEDULE_WORKFLOW' : 'EDIT_SCHEDULE';
      activityCardController.editable = activityCardController.isReadOnly !== true && SecurityService.isAllowed(operationsToEdit, activityCardController.operations);

      Module.children({
        id: activityCardController.module.id
      }).$promise.then((children) => {
        activityCardController.children = children;
        loadData();
      });
    };

    function loadData() {
      const childrenIds = _.map(activityCardController.children, 'id');

      activityCardController.loading = true;
      $q.all([
        Activity.query({
          entityId: _.concat(activityCardController.module.id, childrenIds),
          periodId: activityCardController.period.id,
          categoryId: activityCardController.categoryId
        }).$promise,
        Offering.weeks({
          entityType: 'module',
          entityId: activityCardController.module.id,
          periodId: activityCardController.period.id,
          exceedPeriod: true
        }).$promise,
        CustomField.query({
          rootType: 'method',
          entityType: 'activity'
        }).$promise,
        CustomField.query({
          rootType: 'assessment',
          entityType: 'activity'
        }).$promise
      ]).then(([activities, weeks, methodFields, assessmentFields]) => {
        activityCardController.activities = activities;

        const results = build(activities, weeks);
        const grouped = group(results);

        activityCardController.types = _.map(grouped, (type) => {
          const exam = _.result(type, 'category.exam', false);
          const fields = exam ? assessmentFields : methodFields;
          type.fields = _.keyBy(fields, 'name');
          return type;
        });

        return activityCardController.validate();
      }).finally(() => {
        activityCardController.loading = false;
      });
    }

    activityCardController.showField = function (type, property) {
      const visible = _.get(type.fields[property], 'visible');
      return visible === true && hasAnyValue(type.activities, property);
    };

    function hasAnyValue(container, property) {
      return _(container).map(property).filter(hasValue).value().length > 0;
    }

    function hasValue(value) {
      if (_.isArray(value)) {
        return _.some(value, hasValue);
      }

      return angular.isDefined(value) && value !== 0;
    }

    function build(activities, weeks) {
      return _(activities).map((activity) => {
        const result = angular.copy(activity);
        Dates.setActive([result.type], activityCardController.period);

        const plannedWeeks = _(activity.plannings)
          .map(({ week }) => {
            return _.find(weeks, { week }) || { week };
          })
          .filter(angular.isDefined)
          .value();

        result.weeks = plannedWeeks;
        result.scheduleWeeks = _(plannedWeeks).map('scheduleWeek').filter(angular.isDefined).uniq().sort().value();
        result.periods = _(plannedWeeks).map('period').uniq().sort().value();

        return result;
      }).filter((result) => !_.isEmpty(result.weeks)).sortBy('name').value();
    }

    function group(activities) {
      return _(activities)
        .groupBy((activity) => activity.type.id)
        .map((grouped) => {
          return _.chunk(grouped, MAX_PER_TABLE);
        }).flatten().map((values) => {
          const result = angular.copy(values[0].type);
          result.activities = values;
          return result;
        }).sortBy('sequence').value();
    }

    activityCardController.validate = function() {
      return Activity.validate({
        moduleId: activityCardController.module.id,
        periodId: activityCardController.period.id,
        categoryId: activityCardController.categoryId
      }).$promise.then((validation) => {
        _(activityCardController.types).map('activities').flatten().forEach((activity) => {
          activity.validation = _.find(validation.entities, (it) => it.entity.id === activity.id);
        });

        activityCardController.validation = validation;
        activityCardController.onValidate(validation);
      });
    };

    activityCardController.edit = function (activity) {
      ActivityManager.edit({
        activity: mapToBody(activity),
        category: activity.type.category,
        module: activityCardController.module,
        operations: activityCardController.operations,
        onSave: (result) => save(result)
      });
    };

    function mapToBody(activity) {
      const body = angular.copy(activity);
      body.typeId = _.result(activity, 'type.id');
      return _.omitBy(body, 'type');
    }

    function save(activity) {
      return Activity.store(activity).$promise.then(() => {
        Message.onSaved();
        loadData();
      });
    }

    activityCardController.onChange = function() {
      loadData();
    };
  }
});
