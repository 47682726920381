'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasPlanboardPrint
 * @description
 * uasPlanboardPrint Shows the planboard in printer style.
 */
angular.module('uasApp')
  .component('uasPlanboardPrint', {
    bindings: {
      study: '<'
    },
    templateUrl: 'es6/planboard/planboard-print.html',
    controllerAs: 'planboardController',
    controller: function($q, $log, Period, Planboard, Planning) {
      const planboardController = this;

        planboardController.$onInit = function () {
            planboardController.loading = true;

            $q.all([
                Period.getSelectable(planboardController.study.academicYearId),
                Planning.calendar({
                    studyId: planboardController.study.id
                }).$promise,
                Planning.get({
                    studyId: planboardController.study.id
                }).$promise
            ]).then(([periods, calendar, planning]) => {
                _.forEach(calendar.periods, (period) => {
                    period.visible = true;
                });

                planboardController.periods = _.filter(periods, (period) => 
                    period.calendarId === calendar.id
                );
                planboardController.calendar = calendar;
                calculateColumnWidth();

                planboardController.board = new Planboard(planboardController.study.id);
                planboardController.sumEcts = Planboard.sumEcts;
                planboardController.totalEcts = Planboard.totalEcts;

                planboardController.board.init(
                    planboardController.study,
                    planning.moduleGroups,
                    planboardController.periods,
                    calendar.periods.length,
                    fillGroup);
                planboardController.mode = 'current';
            }).finally(() => {
                planboardController.loading = false;
            });
        };

        planboardController.switchMode = function (mode) {
            planboardController.mode = mode;
            calculateColumnWidth();
            _.forEach(planboardController.board.visibleGroups, (group) => fillGroup(group));
        };

        function getNumberOfPeriods() {
            const periods = _.filter(planboardController.calendar.periods, { visible: true });
            return periods.length;
        }

        function calculateColumnWidth() {
            planboardController.columnWidth = getColumnWidth();
        }

        function getColumnWidth() {
            const numberOfPeriods = getNumberOfPeriods();
            if (numberOfPeriods > 0) {
                return 100 / numberOfPeriods;
            }
            return 0;
        }

        planboardController.togglePeriod = function(period) {
            period.visible = period.visible !== true;
            calculateColumnWidth();
            _.forEach(planboardController.board.visibleGroups, (group) => fillGroup(group));
        };

        planboardController.isVisible = function(item) {
            return item.changeType !== 'REMOVE' || planboardController.mode === 'history';
        };

        planboardController.toggleGroup = function(group) {
            planboardController.board.toggleGroup(group);
        };

        //
        // Layouting of modules in their periods
        //

        function fillGroup(group) {
            const contents = getAllOfferings(group);
            group.empty = contents.length === 0;
            group.rows = buildRows(contents, group);

            _.each(group.children, fillGroup);
        }

        function getAllOfferings(group) {
            return _(group.modules).filter(planboardController.isVisible).map(function (module) {
                return _(module.offerings).filter(function (offering) {
                    return offering.exclude !== true;
                }).filter(planboardController.isVisible).map(function (offering) {
                    return { offering, module };
                }).value();
            }).flatten().filter(function (content) {
                var result = angular.isDefined(content.offering.period);
                if (!result) {
                    $log.warn('Content %o was skipped', content);
                }
                return result;
            }).sortBy(function (content) {
                return content.offering.period.duration;
            }).reverse().value();
        }

        function buildRows(contents) {
            var rows = convertOfferingToRow(contents);
            applyLayout(rows);
            _.each(rows, (row) => _.remove(row, { replace: false }));
            _.remove(rows, (row) => _.every(row, { replace: true }));
            return rows;
        }

        function convertOfferingToRow(contents) {
            return _(contents)
                .filter((content) => angular.isDefined(content.offering.period))
                .map((content) => {
                    const offering = content.offering;
                    const module = content.module;
                    const row = [];

                    _.times(visibleColumns(1, offering.period.start), () => {
                        row.push({ replace: true });
                    });

                    const cell = {};
                    cell.duration = visibleColumns(offering.period.start, offering.period.start + offering.period.duration);
                    cell.period = _.find(planboardController.calendar.periods, { period: offering.period.start });
                    cell.module = angular.copy(module);
                    cell.displayType = planboardController.board.getCombinedDisplayType(module, offering);
                    cell.offering = offering;
                    row.push(cell);

                    _.times(cell.duration - 1, function () {
                        row.push({ replace: false });
                    });

                    const numberOfPeriods = getNumberOfPeriods();
                    _.times(numberOfPeriods - offering.period.end, () => {
                        row.push({ replace: true });
                    });

                    return row;
                })
                .filter((row) => _.some(row, (cell) => cell.duration > 0))
                .value();
        }

        function visibleColumns(start, end) {
            const range = _.range(start, end);

            return _(planboardController.calendar.periods)
                    .filter({ visible: true })
                    .map('period')
                    .filter((period) => _.includes(range, period))
                    .value().length;
        }

        function applyLayout(rows) {
            _.each(rows, function(row, rowNumber) {
                _.each(row, function(cell, columnNumber) {
                    if (angular.isDefined(cell.duration)) {
                        var startColumn = columnNumber;
                        var endColumn = columnNumber + cell.duration;

                        var foundRow = getReplacementRow(rows, rowNumber, startColumn, endColumn);
                        if (angular.isDefined(foundRow)) {
                            _.each(_.range(startColumn, endColumn), function(newColumnNumber) {
                                foundRow[newColumnNumber] = row[newColumnNumber];
                                row[newColumnNumber] = { replace: true };
                            });
                        }
                    }
                });
            });
        }

        function getReplacementRow(rows, endRow, startColumn, endColumn) {
            const slices = _.slice(rows, 0, endRow);
            return _.find(slices, function(row) {
                var columns = _.range(startColumn, endColumn);
                var indices = getIndicesToReplace(row);
                var intersection = _.intersection(columns, indices);
                return intersection.length === columns.length;
            });
        }

        function getIndicesToReplace(row) {
            var indices = [];
            _.each(row, function(cell, index) {
                if (cell.replace === true) {
                    indices.push(index);
                }
            });
            return indices;
        }

    }
});
