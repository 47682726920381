'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:exports
 * @description Shows an interactive list of exports.
 */
angular.module('uasApp')
    .component('exports', {
        templateUrl: 'es6/export/exports.html',
        controllerAs: 'exportsController',
        controller: function(AcademicYear, Export, EntityService, ExportDetails, ExternalSystem, Pageable) {
            const exportsController = this;

            exportsController.$onInit = function() {
                exportsController.parameters = {};
                exportsController.pageable = Pageable.of({
                    order: 'creationTime',
                    reverse: true
                });

                ExternalSystem.query().$promise.then((systems) => {
                    exportsController.systems = systems;
                });

                setDefaultDates();
                exportsController.search();
            };

            exportsController.search = function() {
                exportsController.loading = true;

                const parameters = exportsController.pageable.build(exportsController.parameters);
                Export.get(parameters).$promise.then((exports) => {
                    exportsController.exports = exports;
                }).finally(() => {
                    exportsController.loading = false;
                });
            };

            function setDefaultDates() {
                exportsController.defaultFromDate = new Date().setHours(0, 0, 0, 0);
                exportsController.defaultToDate = new Date().setHours(23, 59, 59, 999);

                _.set(exportsController, 'parameters.fromDate', exportsController.defaultFromDate);
                _.set(exportsController, 'parameters.toDate', exportsController.defaultToDate);
            }

            exportsController.show = function(line) {
                ExportDetails.show([line]);
            };

            exportsController.setDateTimeRange = function() {
                const dateTimeRange = _.get(exportsController, 'dateTimeRange');

                _.assign(exportsController.parameters, {
                    fromDate: _.get(dateTimeRange, 'from'),
                    toDate: _.get(dateTimeRange, 'to')
                });
            };
        }
    });
