'use strict';

angular.module('uasApp').factory('Objective', function (UasResource) {
  return UasResource('/objectives', {
    combined: {
      url: '/objectives/combined',
      method: 'POST',
      isArray: false
    },
    restore: {
      url: '/objectives/:id/restore',
      method: 'PUT',
      isArray: false
    },
    report: {
      url: '/objectives-report',
      method: 'GET',
      isArray: false
    },
    nodes: {
      url: '/objectives-report/nodes',
      method: 'GET',
      isArray: true
    }
  });
});
